import { ExcatItem, ExcatItemContent, ExploreCategory, FlexRow } from "../../style/bodyStyle";
import { MainImage, Pill, Text } from "./styles";

const ExploreSkeleton = () => {
    return (<>
        <div className="section-wrapper">
            <ExploreCategory>
                        <FlexRow className="row g-2 justify-content-center">
                        <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                            <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                            <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                            <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                            <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                            <div className="col-xl-2 col-md-3 col-6 ">
                                <ExcatItem>
                                    <ExcatItemContent>
                                    <Text height="100" width="150" radius="10"></Text>
                                    <Text height="30" width="75" radius="10"></Text>
                                    </ExcatItemContent>
                                    </ExcatItem>
                            </div> 
                 
                          
                         
                        </FlexRow>
                    </ExploreCategory>

            <FlexRow className="explore-wrapper g-4 explore-filter">
                <div className={`nft-item`}>
                    <div className="nft-inner">
                        <div className="nft-item-top d-flex justify-content-between align-items-center">
                            <div className="author-part">
                                <ul className="author-list d-flex">
                                    <li className="single-author d-flex align-items-center">
                                        <Text height="30" width="30" radius="500"></Text>
                                        <Text height="20" width="100" radius="10"></Text>
                                    </li>
                                </ul>
                            </div>
                            <div className="more-part">
                                <div className=" dropstart">
                                    <Text height="10" width="20" radius="10"></Text>
                                </div>
                            </div>
                        </div>

                        <div className="nft-item-bottom">
                            <div className="nft-thumb">
                                <MainImage></MainImage>
                                <Pill></Pill>
                            </div>
                            <div className="nft-content">
                                <div className="content-title">
                                    <Text height="25" width="100" radius="10"></Text>
                                </div>
                                <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                    <Text height="17" width="50" radius="10"></Text>
                                </div>
                                <div className="price-like d-flex justify-content-between align-items-center">
                                    <div className="nft-price d-flex align-items-center">
                                        <span className="currency-img">
                                            <Text height="25" width="25" radius="500"></Text>
                                        </span>
                                        <Text height="17" width="100" radius="10"></Text>
                                    </div>
                                    <Text height="25" width="60" radius="10"></Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`nft-item`}>
                    <div className="nft-inner">
                        <div className="nft-item-top d-flex justify-content-between align-items-center">
                            <div className="author-part">
                                <ul className="author-list d-flex">
                                    <li className="single-author d-flex align-items-center">
                                        <Text height="30" width="30" radius="500"></Text>
                                        <Text height="20" width="100" radius="10"></Text>
                                    </li>
                                </ul>
                            </div>
                            <div className="more-part">
                                <div className=" dropstart">
                                    <Text height="10" width="20" radius="10"></Text>
                                </div>
                            </div>
                        </div>

                        <div className="nft-item-bottom">
                            <div className="nft-thumb">
                                <MainImage></MainImage>
                                <Pill></Pill>
                            </div>
                            <div className="nft-content">
                                <div className="content-title">
                                    <Text height="25" width="100" radius="10"></Text>
                                </div>
                                <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                    <Text height="17" width="50" radius="10"></Text>
                                </div>
                                <div className="price-like d-flex justify-content-between align-items-center">
                                    <div className="nft-price d-flex align-items-center">
                                        <span className="currency-img">
                                            <Text height="25" width="25" radius="500"></Text>
                                        </span>
                                        <Text height="17" width="100" radius="10"></Text>
                                    </div>
                                    <Text height="25" width="60" radius="10"></Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><div className={`nft-item`}>
                    <div className="nft-inner">
                        <div className="nft-item-top d-flex justify-content-between align-items-center">
                            <div className="author-part">
                                <ul className="author-list d-flex">
                                    <li className="single-author d-flex align-items-center">
                                        <Text height="30" width="30" radius="500"></Text>
                                        <Text height="20" width="100" radius="10"></Text>
                                    </li>
                                </ul>
                            </div>
                            <div className="more-part">
                                <div className=" dropstart">
                                    <Text height="10" width="20" radius="10"></Text>
                                </div>
                            </div>
                        </div>

                        <div className="nft-item-bottom">
                            <div className="nft-thumb">
                                <MainImage></MainImage>
                                <Pill></Pill>
                            </div>
                            <div className="nft-content">
                                <div className="content-title">
                                    <Text height="25" width="100" radius="10"></Text>
                                </div>
                                <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                    <Text height="17" width="50" radius="10"></Text>
                                </div>
                                <div className="price-like d-flex justify-content-between align-items-center">
                                    <div className="nft-price d-flex align-items-center">
                                        <span className="currency-img">
                                            <Text height="25" width="25" radius="500"></Text>
                                        </span>
                                        <Text height="17" width="100" radius="10"></Text>
                                    </div>
                                    <Text height="25" width="60" radius="10"></Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><div className={`nft-item`}>
                    <div className="nft-inner">
                        <div className="nft-item-top d-flex justify-content-between align-items-center">
                            <div className="author-part">
                                <ul className="author-list d-flex">
                                    <li className="single-author d-flex align-items-center">
                                        <Text height="30" width="30" radius="500"></Text>
                                        <Text height="20" width="100" radius="10"></Text>
                                    </li>
                                </ul>
                            </div>
                            <div className="more-part">
                                <div className=" dropstart">
                                    <Text height="10" width="20" radius="10"></Text>
                                </div>
                            </div>
                        </div>

                        <div className="nft-item-bottom">
                            <div className="nft-thumb">
                                <MainImage></MainImage>
                                <Pill></Pill>
                            </div>
                            <div className="nft-content">
                                <div className="content-title">
                                    <Text height="25" width="100" radius="10"></Text>
                                </div>
                                <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                    <Text height="17" width="50" radius="10"></Text>
                                </div>
                                <div className="price-like d-flex justify-content-between align-items-center">
                                    <div className="nft-price d-flex align-items-center">
                                        <span className="currency-img">
                                            <Text height="25" width="25" radius="500"></Text>
                                        </span>
                                        <Text height="17" width="100" radius="10"></Text>
                                    </div>
                                    <Text height="25" width="60" radius="10"></Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </FlexRow>

        </div>
    </>);
}

export default ExploreSkeleton;