import Metamask from '../images/wallet/metamask.svg'
import Binance from '../images/wallet/binance.svg'
import Walletconnect from '../images/wallet/walletconnect.svg'
import Formatic from '../images/wallet/formatic.svg'
import Trust from '../images/wallet/trust.svg'
import { Link } from 'react-router-dom'

const UserWallet = () => {
    return ( <>
    <section className="wallet-section padding-top padding-bottom">
        <div className="container">
            <div className="wallet-inner">
                <div className="wallet-title text-center">
                    <h3 className="mb-3">Connect your crypto wallet</h3>
                    <p className="m-auto mb-5">Connect with one of available wallet providers or <Link
                            to="signup.html">create a
                            new wallet</Link></p>
                </div>
                <ul className="nav justify-content-center nav-pills wallet-tab-list" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <div className="nav-link wallet-tab active" id="wallet-tab-1" data-bs-toggle="pill"
                            data-bs-target="#pills-wallet-1" role="tab" aria-controls="pills-wallet-1"
                            aria-selected="true">
                            <img src={Metamask} alt="Metamask"/>
                            <span>Metamask</span>
                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div className="nav-link wallet-tab" id="wallet-tab-2" data-bs-toggle="pill"
                            data-bs-target="#pills-wallet-2" role="tab" aria-controls="pills-wallet-2"
                            aria-selected="false">
                            <img src={Binance} alt="Binance"/><span>Binance</span>

                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div className="nav-link wallet-tab" id="wallet-tab-3" data-bs-toggle="pill"
                            data-bs-target="#pills-wallet-3" role="tab" aria-controls="pills-wallet-3"
                            aria-selected="false">
                            <img src={Formatic} alt="Formatic"/>
                            <span>Formatic</span>
                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div className="nav-link wallet-tab" id="wallet-tab-4" data-bs-toggle="pill"
                            data-bs-target="#pills-wallet-4" role="tab" aria-controls="pills-wallet-4"
                            aria-selected="false">
                            <img src={Walletconnect} alt="WalletConnect"/>
                            <span>Walletconnect</span>
                        </div>
                    </li>
                    <li className="nav-item" role="presentation">
                        <div className="nav-link wallet-tab" id="wallet-tab-4" data-bs-toggle="pill"
                            data-bs-target="#pills-wallet-5" role="tab" aria-controls="pills-wallet-4"
                            aria-selected="false">
                            <img src={Trust} alt="WalletConnect"/>
                            <span>Trust</span>
                        </div>
                    </li>
                </ul>
               
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-wallet-1" role="tabpanel"
                        aria-labelledby="wallet-tab-1">
                        <div className="wallet-content">
                            <div className="wallet-img">
                                <img src={Metamask} alt="Wallet_Name"/>
                            </div>
                            <div className="wallet-desc">
                                <h5>Connect Your MetaMask Wallet</h5>
                                <Link to="#" className="default-btn small-btn move-right"><span>Connect</span> </Link>
                                <Link to="#" target="_blank" rel="noopener noreferrer">Learn how to use Metamask
                                    wallet</Link>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-wallet-2" role="tabpanel" aria-labelledby="wallet-tab-2">
                        <div className="wallet-content">
                            <div className="wallet-img">
                                <img src={Binance} alt="Wallet_Name"/>
                            </div>
                            <div className="wallet-desc">
                                <h5>Connect Your Binance Wallet</h5>
                                <Link to="#" className="default-btn small-btn move-right"> <span>Connect</span></Link>
                                <Link to="#" target="_blank" rel="noopener noreferrer">Learn how to use Binance
                                    wallet</Link>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-wallet-3" role="tabpanel" aria-labelledby="wallet-tab-3">
                        <div className="wallet-content">
                            <div className="wallet-img">
                                <img src={Formatic} alt="Wallet_Name"/>
                            </div>
                            <div className="wallet-desc">
                                <h5>Connect Your Formatic Wallet</h5>
                                <Link to="#" className="default-btn small-btn move-right"><span>Connect</span></Link>
                                <Link to="#" target="_blank" rel="noopener noreferrer">Learn how to use Formatic
                                    wallet</Link>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-wallet-4" role="tabpanel" aria-labelledby="wallet-tab-4">
                        <div className="wallet-content">
                            <div className="wallet-img">
                                <img src={Walletconnect} alt="Wallet_Name"/>
                            </div>
                            <div className="wallet-desc">
                                <h5>Connect Your Walletconnect Wallet</h5>
                                <Link to="#" className="default-btn small-btn move-right"> <span>Connect</span></Link>
                                <Link to="#" target="_blank" rel="noopener noreferrer">Learn how to use WalletConnect
                                    wallet</Link>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-wallet-5" role="tabpanel" aria-labelledby="wallet-tab-4">
                        <div className="wallet-content">
                            <div className="wallet-img">
                                <img src={Trust} alt="Wallet_Name"/>
                            </div>
                            <div className="wallet-desc">
                                <h5>Connect Your Trust Wallet</h5>
                                <Link to="#" className="default-btn small-btn move-right"> <span>Connect</span></Link>
                                <Link to="#" target="_blank" rel="noopener noreferrer">Learn how to use trust
                                    wallet</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section></> );
}
 
export default UserWallet;