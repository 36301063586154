import styled from "styled-components";

export const InputError= styled.div`
    position: absolute;
    font-size: 10px;
    font-style: italic;
    color: #ff0000;`

export const SuccessAlert= styled.div`
padding: 20px;
background: #9fffad66;
margin: 10px 0;
border-radius: 10px;
`
export const DangerAlert= styled.div`
padding: 20px;
background: #ff9f9f66;
margin: 10px 0;
border-radius: 10px;
`
export const  Text = styled.span`
font-size:${(props)=>props.size}px;
color:${(props)=>props.color};
font-weight:${(props)=>props.weight};
`

export const PageConnection = styled.div`
z-index: 999;
margin: auto;
bottom: 0;
display: flex;
position: fixed;
width: 100%;
justify-content: center;
padding: 20px;
background: #b6b6b6;
color: #fff;
`

export const Calculaion = styled.div`
align-self: flex-end !important;
font-size: 12px;
font-weight:500
`