import { useState,useEffect,useCallback } from "react";

const CountDownSmall = ({endTime}) => {
    const [timer, setTimer] = useState()
    

    const CountDownTimer =useCallback((endTime) => {
        let months_arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      
        let unixEndDate = new Date(endTime * 1000);
        let ey = unixEndDate.getFullYear();
        let em = months_arr[unixEndDate.getMonth()];
        let ed = unixEndDate.getDate();
        let eh = "0" + unixEndDate.getHours();
        let emn = "0" + unixEndDate.getMinutes();
        let es = "0" + unixEndDate.getSeconds();
        let realEndTimeFormat = em + ' ' + ed + ', ' + ey + ' ' + eh.substring(-2) + ':' + emn.substring(-2) + ':' + es.substring(-2);
        let EndTimeToUnix = new Date(realEndTimeFormat).getTime()

        let x = setInterval(function () {
            
            let realTime = new Date().getTime();
            let distance = EndTimeToUnix - realTime;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimer(<>  <ul className="nft-countdown count-down" data-date={realEndTimeFormat}>
            <li>
               <span className="days"> {days}</span>
             </li>
             <li>
               <span className="hours">{hours}</span>
             </li>
             <li>
               <span className="minutes">{minutes}</span>
             </li>
             <li>
               <span className="seconds">{seconds}</span>
             </li>

</ul></>)
          
            // setTimer("-" + minutes.substring(-2) + ":" + seconds)
            if (distance < 0) {
                clearInterval(x);               
            }
            else {
               
                setTimer(<>  <ul className="nft-countdown count-down" data-date={realEndTimeFormat}>
                     <li>
                        <span className="days"> {days}</span>
                      </li>
                      <li>
                        <span className="hours">{hours}</span>
                      </li>
                      <li>
                        <span className="minutes">{minutes}</span>
                      </li>
                      <li>
                        <span className="seconds">{seconds}</span>
                      </li>
         
        </ul></>)
            }
        }, 1000);
    },[])

    useEffect(() => {
     
        CountDownTimer( endTime)
       
    }, [CountDownTimer, endTime ])

    return ( <>
   {timer}
    </> );
}
 
export default CountDownSmall;