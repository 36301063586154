import axios from "axios";
import { useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { InputError } from "../style/generalStyle";
import { LoadingButton } from "./components/loading";
import Cookies from 'js-cookie'

const Login = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(true)
    const [user, setUser] = useState()
    const [password, setPassword] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [validateUser, setValidateUser] = useState("")
    const [validatePassword, setValidatePassword] = useState("")
    const loginUser = (e)=>{
        e.preventDefault()
        setIsLoading(true)
        setValidatePassword("")
        setValidateUser("")
        document.getElementById("user").classList.remove("inputError") 
        document.getElementById("password").classList.remove("inputError") 

     // console.log(user, password)
        if (user === undefined || user === "") {
            document.getElementById("user").classList.add("inputError") 
            document.getElementById("password").classList.add("inputError") 
            setValidateUser(<><InputError>* Username cannot be empty</InputError></>)
            setIsLoading(false)
        }
        else if (password === undefined || password === "") {
            document.getElementById("user").classList.add("inputError") 
            document.getElementById("password").classList.add("inputError") 
            setValidatePassword(<><InputError>* Password cannot be empty</InputError></>)
            setIsLoading(false)
        }
        else{
            const payload = {
                useremail: user,
                password: password
            }
                axios.post('https://api.artsfields.com/user/login', payload)
        .then(res=>{
          //  console.log(res)
            Cookies.set('__Logged', res.data.user.userId, { expires: 2 })
            localStorage.setItem('__LUD', JSON.stringify(res.data.user))
            // window.location.reload(false)
            navigate(-1)
            
        })
        .catch(err=>{
            if (err.response.status === 420) {
                document.getElementById("password").classList.add("inputError") 
                setValidatePassword(<><InputError>* Incorrect password</InputError></>)
                setIsLoading(false)
            }
            if (err.response.status === 430) {
                document.getElementById("user").classList.add("inputError") 
                document.getElementById("password").classList.add("inputError") 
                setValidateUser(<><InputError>* This user does not exist</InputError></>)
                setIsLoading(false)
            }
            
        })
        }
    
    }

    useEffect(() => {
            //to check if user is already authenticated
            const log = Cookies.get('__Logged')
            if ( log !== undefined) {
               navigate(-1)
            }

            const handleResize = () => {
                if (window.innerWidth > 767) {
                    setIsMobile(false)
                }
                else if (window.innerWidth < 767) {
                    setIsMobile(true)
                }
            }
            handleResize()
            window.addEventListener("resize", handleResize)
    },[navigate])
    return ( <>
    <div className="login-section padding-top padding-bottom">
        <div className=" container">
            <div className="row g-5 align-items-center flex-md-row-reverse">
                <div className="col-lg-5">
                    <div className="account-wrapper">
                        <h3 className="title">Sign In</h3>
                        <form className="account-form" onSubmit={loginUser}>
                            <div className="form-floating mb-3" id="user">
                                <input type="email" className="form-control" id="floatingInput"
                                    placeholder="name@example.com" onChange={(e)=>setUser(e.target.value)} />
                                <label htmlFor="floatingInput">Email address</label>
                                {validateUser}
                            </div>
                            <div className="form-floating" id="password">
                                <input type="password" className="form-control" id="floatingPassword"
                                    placeholder="Password" onChange={(e)=>setPassword(e.target.value)} />
                                <label htmlFor="floatingPassword">Password</label>
                                {validatePassword}
                            </div>
                            <div className="form-group">
                                <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                    <div className="checkgroup">
                                        <input type="checkbox" name="remember" id="remember"/>
                                        <label htmlFor="remember">Remember Me</label>
                                    </div>
                                    <Link to="forgot-pass.html">Forgot Password?</Link>
                                </div>
                            </div>
                            <div className="form-group">
                              {isLoading ?   <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> : 
                              <>  <button className="d-block default-btn move-top"><span>Signin Now</span></button></>}
                            </div>
                        </form>
                        <div className="account-bottom">
                            <span className="d-block cate pt-10">Don’t Have any Account? <Link to="/signup"> Sign
                                    Up</Link></span>
                        </div>
                    </div>
                </div>
                {isMobile ?  "" :
                <div className="col-lg-7">
                    <div className="account-img">
                        <img src={require(`../images/account/sign-in.png`)} alt="shape_image"/>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    </> );
}
 
export default Login;