import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, Autoplay, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import TopSwipperSkeleton from '../skeleton/bannerSwiper';
import { NFTCurrentImageMini, NFTImage } from '../../style/bodyStyle';
import CountDownSmall from './countdownSmall';

export const TopBannerSwipper = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    axios.post("https://api.artsfields.com/admin/allNfts", {})
      .then(res => {
        setData((res.data.info).slice(0, 3))
        setIsLoading(false)
        //console.log(res.data.info)
      })
  }, [])
  const itemName = (name) => {
    if (name.length < 15) {
      return name
    }
    else {
      return name.substring(0, 15) + "..."
    }
  }

  return (<>

    <Swiper
      grabCursor={true}
      effect={"cards"}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[EffectCards, Autoplay]}
      className="mySwiper"
    >
      {isLoading ? <><TopSwipperSkeleton /></> :
        <>
          {data && data.map((item) => (
            <SwiperSlide key={item.nftId}>
              <div className="nft-item">
                <div className="nft-inner">

                  <div className="nft-item-top d-flex justify-content-between align-items-center">
                    <div className="author-part">
                      <ul className="author-list d-flex">
                        <li className="single-author d-flex align-items-center">
                          <Link to={`/author/${item.owner}`} className="veryfied"><img
                            src={`https://images.artsfields.com/avatar/${item.avatar}`} alt="author-img" /></Link>
                          <h6><Link to={`/author/${item.owner}`}>{item.ownerName}</Link></h6>
                        </li>
                      </ul>
                    </div>
                    <div className="more-part">
                      <div className=" dropstart">
                        <Link className=" dropdown-toggle" to="#" role="button"
                          data-bs-toggle="dropdown" aria-expanded="false"
                          data-bs-offset="25,0">
                          <i className="icofont-flikr"></i>
                        </Link>

                        <ul className="dropdown-menu">
                          <li><Link className="dropdown-item" to="#"><span>
                            <i className="icofont-warning"></i>
                          </span> Report </Link>
                          </li>
                          <li><Link className="dropdown-item" to="#"><span><i
                            className="icofont-reply"></i></span> Share</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="nft-item-bottom">
                    <div className="nft-thumb">
                      <NFTImage>
                        <Link to={`/nft/${item.nftId}`} style={{ backgroundImage: `url('${item.nftImage}')` }}>
                        </Link>
                      </NFTImage>
                      <span className="badge rounded-pill position-absolute"><i
                        className="icofont-heart"></i>
                        1.3k</span>
                    </div>
                    <div className="nft-content">
                      <div className="content-title">
                        <h5>{itemName(item.nftName)}
                        </h5>                      </div>

                      <div
                        className="nft-status d-flex flex-wrap justify-content-between align-items-center ">

                        <div className="nft-stock"> {item.units} in Stock</div>
                      </div>
                      <div
                        className="price-like d-flex justify-content-between align-items-center">
                        <div className="nft-price d-flex align-items-center">
                          <span className="currency-img">
                            <NFTCurrentImageMini src={require(`../../images/currency/SVG/${item.currency.toUpperCase()}.svg`)} alt="currency_img" />
                          </span>
                          <p>{item.price} {item.currency.toUpperCase()}
                          </p>
                        </div>
                        <Link to={`/nft/${item.nftId}`} className="nft-bid">Buy Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </>}
    </Swiper>
  </>)
}

export const LiveAuction = ({ size, data }) => {
  var num = size

  return (
    <>
      <Swiper
        slidesPerView={num}
        spaceBetween={20}

        modules={[Pagination]}
        className="mySwiper"
      >
        {data.map(item => (

          <SwiperSlide key={item.no}>
            <div className="nft-item">
              <div className="nft-inner">

                <div className="nft-item-top d-flex justify-content-between align-items-center">
                  <div className="author-part">
                    <ul className="author-list d-flex">
                      <li className="single-author d-flex align-items-center">
                        <Link to={`/author/${item.owner}`} className="veryfied"><img
                          src={`https://images.artsfields.com/avatar/${item.avatar}`} alt="author-img" /></Link>
                        <h6><Link to={`/author/${item.owner}`}>{item.ownerName}</Link></h6>
                      </li>
                    </ul>
                  </div>
                  <div className="more-part">
                    <div className=" dropstart">
                      <Link className=" dropdown-toggle" to="#" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false"
                        data-bs-offset="25,0">
                        <i className="icofont-flikr"></i>
                      </Link>

                      <ul className="dropdown-menu">
                        <li><Link className="dropdown-item" to="#"><span>
                          <i className="icofont-warning"></i>
                        </span> Report </Link>
                        </li>
                        <li><Link className="dropdown-item" to="#"><span><i
                          className="icofont-reply"></i></span> Share</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="nft-item-bottom">
                  <div className="nft-thumb">
                    <NFTImage>
                      <Link to={`/nft/${item.nftId}`} style={{ backgroundImage: `url('${item.nftImage}')` }}>
                      </Link>
                    </NFTImage>

                    {<CountDownSmall endTime={item.auctionEnds} />}
                    <span className="badge rounded-pill position-absolute"><i
                      className="icofont-heart"></i>
                      1.3k</span>
                  </div>
                  <div className="nft-content">
                    <div className="content-title">
                      <h5><Link to={`/nft/${item.nftId}`}>{item.nftName}</Link> </h5>
                    </div>

                    <div
                      className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                     
                      <div className="nft-stock"> {Number(Number(item.units) - Number(item.sold))} in Stock</div>
                    </div>
                    <div className="price-like d-flex justify-content-between align-items-center">
                      <div className="nft-price d-flex align-items-center">
                        <span className="currency-img">
                          <NFTCurrentImageMini src={require(`../../images/currency/SVG/${item.currency.toUpperCase()}.svg`)} alt="currency_img" />
                        </span>
                        <p>{item.price} {item.currency.toUpperCase()}
                        </p>
                      </div>

                      <Link to={`/nft/${item.nftId}`} className="nft-bid">Place Bid</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>

        ))}
       

      </Swiper>
    </>
  )
}

export const TrendingList = () => {
  return (<>
    <Swiper
      slidesPerView={3}
      spaceBetween={0}
      speed={1200}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      className="myTopSwiper"
    >
      <SwiperSlide>
        <div className="single-thumb"><img
          src={require(`../../images/nft-item/category/01.gif`)}
          alt="col_image" />
        </div>
      </SwiperSlide>
      <SwiperSlide>

        <div className="single-thumb"><img
          src={require(`../../images/nft-item/category/02.gif`)}
          alt="col_image" />
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="single-thumb"><img
          src={require(`../../images/nft-item/category/03.gif`)}
          alt="col_image" />
        </div>
      </SwiperSlide>
    </Swiper>
  </>)
}


export const CollectionList = ({data}) => {
  return (<>
    <Swiper
      slidesPerView={3}
      spaceBetween={0}
      speed={1200}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Pagination, Autoplay]}
      className="myTopSwiper"
    >
      {data && data.map(item=>(
       <SwiperSlide className='swiper-wrapper' style={{alignItems: 'center', height:"200px"}} key={item.nftId}>
        <div className="single-thumb"><img
          src={item.image}
          alt="col_image" />
        </div>
      </SwiperSlide>
      ))}
  
    </Swiper>
  </>)
}