import axios from "axios";
import { useEffect, useState } from "react";
import { LiveAuction } from "../components/Swipers";
import AuctionSkeleton from "../skeleton/auction";

const HomeAuction = () => {
    const [count, setCount] = useState(0)
    const [liveAuction, setLiveAuction] = useState([])
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
               
                setCount(4)
            }
            else if (window.innerWidth < 767) {
                
                setCount(1)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
    },[count])

    useEffect(()=>{
axios.post("https://api.artsfields.com/getData/getLiveAuction",{})
.then(res=>{
    setLiveAuction(res.data.info)
    setIsLoading(false)
})
    },[])

    return ( <>
            <section className="auction-section padding-bottom">
    <div className="container">
        <div className="section-header">
            <h3 className="header-title">Live Auctions</h3>
            <div className="header-content">
                <ul className="arrows d-flex flex-wrap align-items-center">
                    <li className="li arrow-left auction-prev"> <i className="icofont-rounded-left"></i> </li>
                    <li className="li arrow-right auction-next"> <i className="icofont-rounded-right"></i></li>
                </ul>
            </div>
        </div>
        <div className="section-wrapper">
      {isLoading ? <AuctionSkeleton/> : <LiveAuction size={count} data={liveAuction}/>}  
        </div>
    </div>
</section></> );
}
 
export default HomeAuction;