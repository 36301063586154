import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NFTCurrentImageMini, NFTImage } from "../../style/bodyStyle";
import CountDownSmall from "../components/countdownSmall";
import CategorySingleSkeleton from "../skeleton/categorySingle";

const ProfileOnSale = ({ data }) => {
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {

        if (data.length > 0) {

            setIsLoading(false)
        }
        else {

            setIsLoading(true)
        }
    }, [data])
    const itemName = (name) => {
        if (name.length < 15) {
            return name
        }
        else {
            return name.substring(0, 15) + "..."
        }
    }
    return (<>
        {isLoading ? <><CategorySingleSkeleton /></> :
            <>
                {data && data.map((item) => (
                    <div className="col-lg-4 col-sm-6" key={item.nftId}>
                        <div className="nft-item">
                            <div className="nft-inner">
                                <div className="nft-item-top d-flex justify-content-between align-items-center">
                                    <div className="author-part">
                                        <ul className="author-list d-flex">
                                            <li className="single-author d-flex align-items-center">
                                                <Link to={`/author/${item.owner}`} className="veryfied"><img
                                                    src={`https://images.artsfields.com/avatar/${item.avatar}`} alt="author-img" /></Link>
                                                <h6><Link to={`/author/${item.owner}`}>{item.ownerName}</Link></h6>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="more-part">
                                        <div className=" dropstart">
                                            <Link className=" dropdown-toggle"
                                                to="#" role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                data-bs-offset="25,0">
                                                <i
                                                    className="icofont-flikr"></i>
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li><Link className="dropdown-item"
                                                    to="#"><span>
                                                        <i
                                                            className="icofont-warning"></i>
                                                    </span> Report </Link>
                                                </li>
                                                <li><Link className="dropdown-item"
                                                    to="#"><span><i
                                                        className="icofont-reply"></i></span>
                                                    Share</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="nft-item-bottom">
                                    <div className="nft-thumb">
                                        <NFTImage>
                                            <Link to={`/nft/${item.nftId}`} style={{ backgroundImage: `url('${item.nftImage}')` }}>
                                            </Link>
                                        </NFTImage>

                                        {<CountDownSmall endTime={item.auctionEnds} />}
                                        <span
                                            className="badge rounded-pill position-absolute"><i
                                                className="icofont-heart"></i>
                                            1.3k</span>
                                    </div>
                                    <div className="nft-content">
                                        <div className="content-title">
                                        <h5><Link to={`/nft/${item.nftId}`}>{itemName(item.nftName)}</Link> </h5>
                                        </div>

                                        <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                        <div className="nft-stock"> {Number(Number(item.units) - Number(item.sold))} in Stock</div>
                                        </div>
                                        <div
                                            className="price-like d-flex justify-content-between align-items-center">
                                            <div
                                                className="nft-price d-flex align-items-center">
                                                <span className="currency-img">
                                                <NFTCurrentImageMini src={require(`../../images/currency/SVG/${item.currency.toUpperCase()}.svg`)} alt="currency_img" />
                                                </span>
                                                <p>{item.price} {item.currency.toUpperCase()}
                                                </p>
                                            </div>

                                            <Link to={`/nft/${item.nftId}`} className="nft-bid">view</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>}

    </>);
}

export default ProfileOnSale;