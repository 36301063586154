import axios from "axios";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ExploreCategory, FlexRow, ExcatItem, ExcatItemContent, ExploreList, NFTImage, NFTCurrentImageMini } from "../style/bodyStyle";
import ExploreSkeleton from "./skeleton/explore";

const Explore = () => {

    const filterSelection = useCallback((c) => {
        var x, i;
        x = document.getElementsByClassName("nft-item");
        if (c === "all") c = "";
        for (i = 0; i < x.length; i++) {
            RemoveClass(x[i], "show");
            if (x[i].className.indexOf(c) > -1) AddClass(x[i], "show");
        }
    }, [])

    function RemoveClass(element, name) {
        var i, arr1, arr2;
        arr1 = element.className.split(" ");
        arr2 = name.split(" ");
        for (i = 0; i < arr2.length; i++) {
            while (arr1.indexOf(arr2[i]) > -1) {
                arr1.splice(arr1.indexOf(arr2[i]), 1);
            }
        }
        element.className = arr1.join(" ");
    }

    function AddClass(element, name) {
        var i, arr1, arr2;
        arr1 = element.className.split(" ");
        arr2 = name.split(" ");
        for (i = 0; i < arr2.length; i++) {
            if (arr1.indexOf(arr2[i]) === -1) { element.className += " " + arr2[i]; }
        }
    }
    const [data, setData] = useState([]);
    const [nfts, setNfts] = useState([]);
    const [nftLoading, setNftLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            const arrange = () => {
                filterSelection('all')
                var btnContainer = document.getElementById("explore-category");
                var btns = btnContainer.getElementsByClassName("excat-item");
                for (var i = 0; i < btns.length; i++) {
                    btns[i].addEventListener("click", function () {
                        var current = document.getElementsByClassName("active");
                        current[0].className = current[0].className.replace(" active", "");
                        this.className += " active";
                    });
                }
            }
            await axios.post("https://api.artsfields.com/admin/allCategories", {}).then(res => {
                setData(res.data.info)
               
            })
                .catch(err => {
                    console.log(err.response)
                })

            await axios.post("https://api.artsfields.com/admin/allNfts", {})
                .then(res => {
                    setNfts(res.data.info)
                   
                    setNftLoading(false)
                })

            arrange()

        }

        getData()
    }, [filterSelection, setData])

    const encryperate = (image) => (`data:image/png;base64,${image}`)
    const itemName = (name) => {
        if (name.length < 15){
            return name
        }
        else{
          return name.substring(0,15)+"..."
        }
    }
     
    return (<>
        <section className="explore-section explore-page padding-top padding-bottom">
            <div className="container">
                {nftLoading ? <><ExploreSkeleton /></> :
                    <div className="section-wrapper">
                        <ExploreCategory id="explore-category">
                            <FlexRow className="row g-2 justify-content-center">
                                {data && <div className="col-xl-2 col-md-3 col-6 " key='all'>
                                    <ExcatItem className="excat-item active" >
                                        <ExcatItemContent onClick={() => filterSelection('all')}><i
                                            className="icofont-cubes"></i>
                                            All</ExcatItemContent>
                                    </ExcatItem>
                                </div>
                                }
                                {data.map((item) => (
                                    <div className="col-xl-2 col-md-3 col-6 " key={item.id}>
                                        <ExcatItem className="excat-item" >
                                            <ExcatItemContent onClick={() => filterSelection(item.id)}> <span> <img src={encryperate(item.symbol)} alt="Category_Image" width="40px" /></span>
                                                {item.name} </ExcatItemContent>
                                        </ExcatItem>
                                    </div>

                                ))}


                            </FlexRow>
                        </ExploreCategory>

                        <FlexRow className="explore-wrapper g-4 explore-filter">
                            {
                                nfts && nfts.map((item) => (
                                    <ExploreList className={`nft-item ${item.catId}`} key={item.nftId}>
                                        <div className="nft-inner">
                                            <div className="nft-item-top d-flex justify-content-between align-items-center">
                                                <div className="author-part">
                                                    <ul className="author-list d-flex">
                                                        <li className="single-author d-flex align-items-center">
                                                            <Link to={`/author/${item.owner}`} className="veryfied"><img
                                                                src={`https://images.artsfields.com/avatar/${item.avatar}`} alt="author-img" /></Link>
                                                            <h6><Link to={`/author/${item.owner}`}>{item.ownerName}</Link></h6>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="more-part">
                                                    <div className=" dropstart">
                                                        <Link className=" dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown"
                                                            aria-expanded="false" data-bs-offset="25,0">
                                                            <i className="icofont-flikr"></i>
                                                        </Link>

                                                        <ul className="dropdown-menu">
                                                            <li><Link className="dropdown-item" to="#"><span>
                                                                <i className="icofont-warning"></i>
                                                            </span> Report </Link>
                                                            </li>
                                                            <li><Link className="dropdown-item" to="#"><span><i
                                                                className="icofont-reply"></i></span>
                                                                Share</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="nft-item-bottom">
                                                <div className="nft-thumb">
                                                    <NFTImage>
                                                        <Link to={`/nft/${item.nftId}`} style={{ backgroundImage: `url('${item.nftImage}')` }}>
                                                        </Link>
                                                    </NFTImage>
                                                 
                                                    <span className="badge rounded-pill position-absolute"><i className="icofont-heart"></i>
                                                        1.3k</span>
                                                </div>
                                                <div className="nft-content">
                                                    <div className="content-title">
                                                        <h5>{itemName(item.nftName)}
                                                        </h5>
                                                    </div>

                                                    <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">

                                                        <div className="nft-stock"> {item.units} in Stock</div>
                                                    </div>
                                                    <div className="price-like d-flex justify-content-between align-items-center">
                                                        <div className="nft-price d-flex align-items-center">
                                                            <span className="currency-img">
                                                                <NFTCurrentImageMini src={require(`../images/currency/SVG/${item.currency.toUpperCase()}.svg`)} alt="currency_img" />
                                                            </span>
                                                            <p>{item.price} {item.currency.toUpperCase()}
                                                            </p>
                                                        </div>
                                                        <Link to={`/nft/${item.nftId}`} className="nft-bid">Buy Now</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ExploreList>
                                ))}
                        </FlexRow>
                        {/* <div className="text-center mt-5">
                            <Link to="explore.html" className="default-btn move-bottom"><span>Load More</span>
                            </Link>
                        </div> */}
                    </div>
                }

            </div>
        </section>
    </>);
}

export default Explore;