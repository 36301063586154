import axios from "axios";

const LastestNews = () => {

    const options = {
        method: 'GET',
        url: 'https://cryptocurrency-and-nft-news.p.rapidapi.com/news',
        headers: {
          'X-RapidAPI-Key': 'cc36bf12a7msh0e05ae1aedf7fdcp15d5d3jsn09567290a100',
          'X-RapidAPI-Host': 'cryptocurrency-and-nft-news.p.rapidapi.com'
        }
      };
      
      axios.request(options).then(function (response) {
          console.log(response.data);
      }).catch(function (error) {
          console.error(error);
      });
    return ( <>
    <section className="blog-section pb-120">
    <div className="container">
        <div className="section-header">
            <h3 className="header-title"> Latest News</h3>
            <div className="header-content"><a href="blog.html"
                    className="default-btn style-2 small-btn move-right"><span>View All
                        <i className="icofont-circled-right"></i></span></a> </div>
        </div>

        <div className="section-wrapper">
            <div className="blog-wrapper">
                <div className="row justify-content-center g-4">
                    <div className="col-lg-4 col-sm-6">
                        <div className="nft-item blog-item">
                            <div className="nft-inner">
                                <div className="nft-thumb">
                                    <img src={require(`../../images/nft-item/blog/01.jpg`)} alt="blog-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h4><a href="blog-single.html">Learn More about NFT</a> </h4>
                                        <div className="meta-info">
                                            <p><span><i className="icofont-ui-calendar"></i></span>Jan 20
                                                2022
                                            </p>
                                            <p><span><i className="icofont-user"></i></span>Jhon doe
                                            </p>
                                        </div>
                                        <a href="blog-single.html"
                                            className="default-btn move-right small-btn"><span>Read
                                                More</span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="nft-item blog-item">
                            <div className="nft-inner">
                                <div className="nft-thumb">
                                    <img src={require(`../../images/nft-item/blog/02.jpg`)} alt="blog-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h4><a href="blog-single.html">Trending NFT 2022</a> </h4>
                                        <div className="meta-info">
                                            <p><span><i className="icofont-ui-calendar"></i></span>July
                                                20 2022
                                            </p>
                                            <p><span><i className="icofont-user"></i></span>Jhon doe
                                            </p>
                                        </div>
                                        <a href="blog-single.html"
                                            className="default-btn move-right small-btn"><span>Read
                                                More</span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="nft-item blog-item">
                            <div className="nft-inner">
                                <div className="nft-thumb">
                                    <img src={require(`../../images/nft-item/blog/03.jpg`)} alt="blog-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h4><a href="blog-single.html">Decentrelize Crypto ... </a> </h4>
                                        <div className="meta-info">
                                            <p><span><i className="icofont-ui-calendar"></i></span>Dec 20
                                                2022
                                            </p>
                                            <p><span><i className="icofont-user"></i></span>rasselmrh
                                            </p>
                                        </div>
                                        <a href="blog-single.html"
                                            className="default-btn move-right small-btn"><span>Read
                                                More</span> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    </> );
}
 
export default LastestNews;