
import axios from "axios";
import { useEffect, useState} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DangerAlert, SuccessAlert } from "../../style/generalStyle";
import { LoadingButton } from "../components/loading";


const UserVerify = () => {
    const navigate= useNavigate();
    const params = useParams()
    const email = params.email
    const userId = params.userId
    const token = params.token
    const [isMobile, setIsMobile] = useState(false);
    const [responseData, setResponseData] = useState()
    
    useEffect(()=>{
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            }
            else if (window.innerWidth < 767) {
                setIsMobile(true)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)

        //check Token to know status
        axios.post('https://api.artsfields.com/auth/verifyToken',{
            token: token,
            userId: userId
        }).then(response =>{
           console.log(response.data)
           if (response.data.valid ){
               
             axios.post('https://api.artsfields.com/user/updateUserStatus',{status:1, userId:userId})
                  .then(() =>{
                    axios.post('https://api.artsfields.com/user/assignWallet',{coin:"ETH", userId:userId})
                    .then(()=>{
                        console.log(response.data)
                    setResponseData(<> <form className="account-form">
                    <SuccessAlert>Email Verification Succesful. Click the Signin button below to continue.</SuccessAlert>
                       <div className="form-group">
                       <button className="d-block default-btn move-top" onClick={(e)=>navigate("/login")}><span>Signin Now</span></button>
                       </div>
                   </form></>)
                    });
                    

                  })
           }
           else if (!response.data.valid){
            setResponseData(<> <form className="account-form">
            <DangerAlert>Email Verificatiion failed. Sorry this link has expired.</DangerAlert>
               <div className="form-group">
               <button className="d-block default-btn move-top" onClick={(e)=>{e.preventDefault();resendRequest()}}><span>Resend Verificatiion</span></button>
               </div>
           </form></>)
        }
        }).catch(error=>{
            setResponseData(<> <form className="account-form">
            <DangerAlert>Email Verificatiion fialed. Invalid Token Detected</DangerAlert>
               <div className="form-group">
               <button className="d-block default-btn move-top" onClick={(e)=>resendRequest()}><span>Resend Verificatiion</span></button>
               </div>
           </form></>)
        })
        const resendRequest = () => {
            setResponseData(<> <form className="account-form"> <div className="form-group">
                <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button></div></form></>)
                 const type = "reg"
                 const time = Math.floor(Date.now() / 1000)
                 const expires = Math.floor(time + 172800)  //2 hours
                 axios.post('https://api.artsfields.com/auth/createToken', {
                     email: email,
                     expires: expires,
                     type: type,
                     userId: userId
                 })
                     .then((response) => {
                         const token = response.data.token
                         axios.post(`https://api.artsfields.com/mailer/verificationemail`, {
                             email: email,
                             token: token,
                             userId: userId
                         })
                             .then(() => {
                                 setResponseData(<SuccessAlert>Verificatiion message resent to {email}. <span className="d-block cate pt-10">Did'nt recieve an email?<Link to="#" onClick={(e)=>{e.preventDefault();resendToken(userId,token)}}>Resend Mail</Link></span></SuccessAlert>)
                             })
                             .catch((error) => {
                                 console.log(error.response)
                             })
                         // navigate('/auth', {state: {user:response.data.user, messageId:messageId}})
                     })
     
             
         }
         const resendToken = (userId, token) => {
            setResponseData(<> <form className="account-form"> <div className="form-group">
                <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button></div></form></>)
            axios.post(`https://api.artsfields.com/mailer/verificationemail`, {
            email: email,
            token: token,
            userId: userId
        })
            .then(() => {
                setResponseData(<SuccessAlert>Verificatiion message resent to {email}. <span className="d-block cate pt-10">Did'nt recieve an email?<Link to="#" onClick={resendRequest}>Resend Mail</Link></span></SuccessAlert>)
            })
            .catch((error) => {
                console.log(error.response)
            })
         }
    },[token, userId, email, navigate]);

   
    return ( <>
    <div className="login-section padding-top padding-bottom">
        <div className=" container">
            <div className="row g-5 align-items-center flex-md-row-reverse">
                <div className="col-lg-5">
                    <div className="account-wrapper">
                        <h3 className="title">Email Verificatiion</h3>
                        {responseData}
                    </div>
                </div>
                {isMobile ?  "" :
                <div className="col-lg-7">
                    <div className="account-img">
                        <img src={require(`../../images/account/01.png`)} alt="shape_image"/>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    </> );
}
 
export default UserVerify;