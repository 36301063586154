import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CollectionsTopBanner = () => {
const [isLoggedIn, setIsLoggedIn]= useState(false)
useEffect(() => {
    const log = Cookies.get('__Logged')
    if (log === "" || log === undefined) {
        setIsLoggedIn(false)
    }  
    else {
        setIsLoggedIn(true)
    }

},[isLoggedIn])
    return ( <>
    <section className="banner-section style-1" style={{backgroundImage: `url(assets/images/banner/bg.jpg)`}}>
    <div className="container">
        <div className="banner-wrapper">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-8">
                    <div className="banner-content text-center">
                        <h2>Discover Collections
                            of Extraordinary NFTs</h2>
                            {isLoggedIn ? <>
                                <div className="banner-btns d-flex flex-wrap justify-content-center">
                                <Link to="/author" className="default-btn style-2 move-right"><span>Create Collection</span>
                            </Link>
                            
                        </div>
                            </> :<>
                             <div className="banner-btns d-flex flex-wrap justify-content-center">
                             <Link to="/login" className="default-btn style-2 move-right"><span>Create Store</span>
                            </Link>
                        </div>
                            </>}
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="banner_shape">
        <div className="banner_shape__1">
            <div className="row g-3 mb-3">
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/01.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/3.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/05.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/5.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/6.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/4.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/03.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/7.jpg`)} alt="banner_item"/>
                    </div>
                </div>
            </div>
            <div className="row g-3">
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/01.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/3.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/7.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/6.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/4.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/03.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/9.jpg`)} alt="banner_item"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="banner_shape__2">
            <div className="row g-3 mb-3">
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/01.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/3.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/05.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/5.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/6.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/4.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/03.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/7.jpg`)} alt="banner_item"/>
                    </div>
                </div>
            </div>
            <div className="row g-3">
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/01.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/1.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/3.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/02.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/7.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/2.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/6.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/4.jpg`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/03.gif`)} alt="banner_item"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="banner_shape__item">
                        <img src={require(`../../images/nft-item/category/9.jpg`)} alt="banner_item"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    
    </> );
}
 
export default CollectionsTopBanner;