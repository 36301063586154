import styled from "styled-components";

export const Footer =styled.footer`
overflow: hidden;
`
export const FooterNewletter = styled.div`

`
export const FooterTop =styled.div`
background-size: cover;
background-position: center;
background-image: var(--footer-image);
${FooterNewletter}{
    @media (min-width: 992px)
{
    padding-block: 60px;
}

{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-block: 50px;
}
}
`
