import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PairExchange } from "../components/coinExchange";
import { formatBytes } from "../components/convertImageSize";
import TimerCountDown from "../components/timeCountDown";
import { convertTime } from "../components/timeDateConvert";
import NFTdetailsSkeleton from "../skeleton/nftDetails";
import AddAuction from "./addAuction";
import PlaceBidModal from "./placeBidModal";
import PurchaseToken from "./purchaseToken";


const NftDetails = () => {
    const navigate = useNavigate()
    const param = useParams()
    const id = param.id
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [expired, setIsExpires] = useState(false);
    const [bids, setBids] = useState([])
    const [modalType, setModalType] = useState('1')
    const [auctionInfo, setAuctionInfo] = useState([])
    const [itemUnits, setItemUnits] = useState()

    const refPage = useCallback(() => {
        axios.post("https://api.artsfields.com/getData/getNFTid", { id: id })
            .then(res => {
                // console.log(res.data)
                setData(res.data.info)
                if (res.data.info.onAuction === "1") {
                    axios.post("https://api.artsfields.com/getData/getAuctionById", { nftId: id })
                        .then(res => {
                             // console.log(res.data)
                            if (res.data.auction === null) {
                                setIsExpires(true)
                            }
                            else {
                                setAuctionInfo(res.data.auction)
                                setItemUnits(res.data.auction.units)
                                setIsExpires(false)
                            }


                        })

                }
            })

    }, [id])
    useEffect(() => {
        axios.post("https://api.artsfields.com/getData/getNFTid", { id: id })
            .then(res => {
                // console.log(res.data)
                setData(res.data.info)
                setItemUnits(res.data.info.units)
                if (res.data.info.onAuction === "1") {
                    axios.post("https://api.artsfields.com/getData/getAuctionById", { nftId: id })
                        .then(res => {
                            //  console.log(res.data)
                            if (res.data.auction === null) {
                                setIsExpires(true)
                                setIsLoading(false)
                            }
                            else {
                                setAuctionInfo(res.data.auction)
                                setItemUnits(res.data.auction.units)
                                setIsExpires(false)
                                setIsLoading(false)
                            }


                        })

                }
                else {
                    setIsExpires(true)
                    setIsLoading(false)
                }
            })
            .catch(err => {
                //console.log(err.response)
                navigate("/404")
            })
    }, [id, navigate])

    useEffect(() => {
        const placeBid = () => {
            axios.post("https://api.artsfields.com/getData/getBidsById", { id: id })
                .then(res => {
                    setBids(res.data.bids)
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
        setInterval(() => {
            placeBid()
            refPage()
        }, 20000);
        placeBid()
    }, [id, refPage])

   
    const openModal = ((e) => {
        e.preventDefault()
        if (Cookies.get("__Logged") === undefined || Cookies.get("__Logged") === "") {
            navigate("/login");
        }
        else {
            setModalType(e.target.id)
            let modal = document.getElementById("myModal");
            modal.style.display = "block";
            modal.style.transition = "1s ease-in";
        }


    })

    return (<>
        <div className="item-details-section padding-top padding-bottom">
            <div className="container">
                <div className="item-details-wrapper">
                    {isLoading ? <><NFTdetailsSkeleton /></> :
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="item-desc-part">
                                    <div className="item-desc-inner">
                                        <div className="item-desc-thumb">
                                            <img src={data.image} alt="item-img" />
                                        </div>
                                        <div className="item-desc-content">
                                            <nav>
                                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                    <button className="nav-link active" id="nav-details-tab" data-bs-toggle="tab"
                                                        data-bs-target="#nav-details" type="button" role="tab"
                                                        aria-controls="nav-details" aria-selected="true">Details</button>
                                                    <button className="nav-link" id="nav-bids-tab" data-bs-toggle="tab"
                                                        data-bs-target="#nav-bids" type="button" role="tab"
                                                        aria-controls="nav-bids" aria-selected="false">Bids</button>
                                                    <button className="nav-link" id="nav-history-tab" data-bs-toggle="tab"
                                                        data-bs-target="#nav-history" type="button" role="tab"
                                                        aria-controls="nav-history" aria-selected="false">History</button>
                                                </div>
                                            </nav>
                                            <div className="tab-content" id="nav-tabContent">
                                                <div className="details-tab tab-pane fade show active" id="nav-details"
                                                    role="tabpanel" aria-labelledby="nav-details-tab">

                                                    <p>{data.description}</p>
                                                    <div className="author-profile d-flex flex-wrap align-items-center gap-15">
                                                        <div className="author-p-thumb">
                                                            <Link to={`/author/${data.userId}`}><img
                                                                src={`https://images.artsfields.com/avatar/${data.userAvatar}`}
                                                                alt="author-img " /></Link>
                                                        </div>
                                                        <div className="author-p-info">
                                                            <p className="mb-0">Owner</p>
                                                            <h6><Link to={`/author/${data.userId}`}>{data.userName}</Link></h6>
                                                        </div>
                                                    </div>
                                                    <ul className="other-info-list">
                                                        <li className="item-other-info">
                                                            <div className="item-info-title">
                                                                <h6>Contact Address</h6>
                                                            </div>
                                                            <div className="item-info-details">
                                                                <div id="cryptoCode" className="crypto-page">
                                                                    <input id="cryptoLink" value={`${data.contract}`} disabled={true} />
                                                                    <div id="cryptoCopy" data-bs-toggle="tooltip"
                                                                        data-bs-placement="top" title="Copy Address">
                                                                        <span className="copy-icon">
                                                                            <i className="icofont-ui-copy" aria-hidden="true"
                                                                                data-copytarget="#cryptoLink"></i>
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </li>
                                                        <li className="item-other-info">
                                                            <div className="item-info-title">
                                                                <h6>Token ID</h6>
                                                            </div>
                                                            <div className="item-info-details">
                                                                <p>{data.nftId}</p>
                                                            </div>

                                                        </li>
                                                        <li className="item-other-info">
                                                            <div className="item-info-title">
                                                                <h6>Blockchain</h6>
                                                            </div>
                                                            <div className="item-info-details">
                                                                <p>Ethereum (ETH)</p>
                                                            </div>
                                                        </li>

                                                        <li className="item-other-info">
                                                            <div className="item-info-title">
                                                                <h6>Size</h6>
                                                            </div>
                                                            <div className="item-info-details">
                                                                <p>{data.imageHeight} x {data.imageWidth} px. {(`${data.imageType}`).toUpperCase()} ({formatBytes(Number(data.imageSize))})</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="tab-pane fade" id="nav-bids" role="tabpanel" aria-labelledby="nav-bids-tab">
                                                    {bids.length === 0 ? <>
                                                        <span><i className="bids-tab icofont-law-order"></i></span>
                                                        <p>No active bids yet. Be the first to make a bid!</p>
                                                    </> : <>
                                                        {bids && bids.map((item) => (
                                                            <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4" key={item.id}>
                                                                <div className="lab-thumb me-3 me-md-4">
                                                                    <img src={`https://images.artsfields.com/avatar/${item.avatar}`} alt="img" width='70px' />
                                                                </div>
                                                                <div className="lab-content">
                                                                    <p className="mb-2">Bidding: <b> {item.amount} ETH</b></p>
                                                                    <p className="mb-2">By: <b><Link to={`/author/${item.userId}`}>@{item.username}</Link></b></p>
                                                                    <p className="mb-2">At: <b>{convertTime(item.date)}</b></p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>}

                                                </div>
                                                <div className="history-tab tab-pane fade" id="nav-history" role="tabpanel" aria-labelledby="nav-history-tab">
                                                    <ul className="item-histo-list">
                                                        <li className="histo-item">
                                                            <p>Created by <Link to={`/author/${data.userId}`}>@{data.userName}</Link></p>
                                                            <time>{convertTime(data.addedDate)}</time>
                                                        </li>
                                                        <li className="histo-item">
                                                            <p>Listed by <Link to={`/author/${data.userId}`}>@{data.userName}</Link></p>
                                                            <time>{convertTime(data.addedDate)}</time>
                                                        </li>
                                                        <li className="histo-item">
                                                            <p>Owned by <Link to={`/author/${data.userId}`}>@{data.userName}</Link></p>
                                                            <time>{convertTime(data.addedDate)}</time>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="item-buy-part">


                                    <div className="nft-item-title">
                                        {expired ? <>
                                            <h3>#00{Number(data.sold) + Number(1)} {data.nftName}: size {Number(data.sold) + Number(1)}/{data.units}</h3>
                                            <div className="share-btn">
                                                <div className="dropstart">
                                                    <Link className=" dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown"
                                                        aria-expanded="false" data-bs-offset="25,0">
                                                        <i className="icofont-share-alt"></i>
                                                    </Link>

                                                    <ul className="dropdown-menu">
                                                        <li><Link className="dropdown-item" to="#"><span>
                                                            <i className="icofont-twitter"></i>
                                                        </span> Twitter </Link>
                                                        </li>
                                                        <li><Link className="dropdown-item" to="#"><span><i
                                                            className="icofont-telegram"></i></span> Telegram</Link></li>
                                                        <li><a className="dropdown-item" href={`mailto:?subject="Check out this NFT on artsfields.com"&body="Buy ${data.nftName} on <a href="https://artsfields.com/nft/${data.nftId}"> artsfields.com </a> today for only ${data.currency}${data.price}`}><span><i
                                                            className="icofont-envelope"></i></span> Email</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </> : <>
                                            <h3>{data.nftName}: #{Number(auctionInfo.sold) + 1}/{auctionInfo.units}</h3>
                                            <div className="share-btn">
                                                <div className="dropstart">
                                                    <Link className=" dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown"
                                                        aria-expanded="false" data-bs-offset="25,0">
                                                        <i className="icofont-share-alt"></i>
                                                    </Link>

                                                    <ul className="dropdown-menu">
                                                        <li><Link className="dropdown-item" to="#"><span>
                                                            <i className="icofont-twitter"></i>
                                                        </span> Twitter </Link>
                                                        </li>
                                                        <li><Link className="dropdown-item" to="#"><span><i
                                                            className="icofont-telegram"></i></span> Telegram</Link></li>
                                                        <li><a className="dropdown-item" href={`mailto:?subject="Check out this NFT on artsfields.com"&body="Buy ${data.nftName} on <a href="https://artsfields.com/nft/${data.nftId}"> artsfields.com </a> today for only ${data.currency}${data.price}`}><span><i
                                                            className="icofont-envelope"></i></span> Email</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </>}

                                    </div>

                                    <div className="item-details-countdown">
                                        {
                                            expired ? <></> :
                                                <><h4>Ends In:</h4>
                                                    {data.addedDate && <TimerCountDown endTime={Number(auctionInfo.ends)} />}</>
                                        }

                                    </div>
                                    <div className="item-price">

                                        {
                                            expired ? <>
                                                <h4>Price</h4>
                                                <p><span><i className="icofont-coins"></i> {data.price} {(`${data.currency}`).toUpperCase()}
                                                </span> ($<PairExchange currency="USD" coin={data.currency} price={data.price} />)</p></>

                                                :
                                                <>
                                                    <h4>Auction Price</h4>
                                                    <p style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <span><i className="icofont-coins"></i> {auctionInfo.amount} {(`${data.currency}`).toUpperCase()}
                                                            </span>
                                                            <span style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500' }}>
                                                                ($<PairExchange currency="USD" coin={data.currency} price={auctionInfo.amount} />)
                                                            </span>
                                                        </span>
                                                        <span style={{ fontSize: '14px', fontWeight: '400', textDecoration: 'line-through', marginLeft: '30px' }}>
                                                            {data.price}{(`${data.currency}`).toUpperCase()}
                                                        </span>
                                                    </p>
                                                </>
                                        }


                                    </div>
                                    {Cookies.get("__Logged") !== undefined ? <>
                                        {(data.userId === JSON.parse(localStorage.getItem('__LUD')).userId) ?
                                            <>
                                                <div className="buying-btns d-flex flex-wrap">
                                                    <Link to="#" className="default-btn move-right"><span>End Sales</span> </Link>
                                                    {
                                                        expired ? <>
                                                            <Link to="#" className="default-btn style-2 move-right" id={3} onClick={openModal}><span id={3} onClick={openModal}>Add to Auction</span>
                                                            </Link></> : <>
                                                            <Link to="#" className="default-btn style-2 move-right" id={4} onClick={openModal}><span id={4} onClick={openModal}>End Auction</span>
                                                            </Link></>}
                                                </div>

                                            </> : <>
                                                <div className="buying-btns d-flex flex-wrap">
                                                    <Link to="#" className="default-btn move-right" id={2} onClick={openModal}><span id={2} onClick={openModal}>Buy Token</span></Link>
                                                    {
                                                        expired ? <> <Link to="#" className="default-btn style-2 move-right" id={1} onClick={openModal}><span id={1} onClick={openModal}>Place a Bid</span>
                                                        </Link></> : <>
                                                        </>}
                                                </div>

                                            </>

                                        }
                                    </> : <>  <div className="buying-btns d-flex flex-wrap">
                                        <Link to="/wallet" className="default-btn move-right" id={2} onClick={openModal}><span id={2} onClick={openModal}>Buy Token</span></Link>
                                        {
                                            expired ? <> <Link to="#" className="default-btn style-2 move-right" id={1} onClick={openModal}><span id={1} onClick={openModal}>Place a Bid</span>
                                            </Link></> : <>
                                            </>}
                                    </div></>}

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
        <div id="myModal" className="modal">
            <div className="modal-content">

                {modalType === "1" ? <PlaceBidModal data={data} id={id} auction={auctionInfo} /> : modalType === "2" ? <PurchaseToken data={data} id={id} units={Number(itemUnits) - Number(data.sold)} /> : modalType === "3" ? <AddAuction data={data} id={id} /> : <></>}
            </div>
        </div>
    </>);


}

export default NftDetails;