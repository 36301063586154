import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleCollection from "./collections/single";
import CollectionSingleSkeleton from "./skeleton/collectionSingle";


const CollectionSingle = () => {
    const param = useParams()
    const id = param.id
    const [data, setData] = useState([]);
    const [username, setUsername] = useState('');
    const [avatar, setAvatar] = useState('');
    const [userId, setUserId] = useState('')
    const [collectionName, setCollectionName] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    useEffect(() => {
        axios.post("https://api.artsfields.com/getData/getCollectionById", { id: id })
            .then(res => {

                // console.log(res.data.col);
                if (res.data.col.length === 0 || res.data.col === [] || res.data.col === null) {
                    navigate('/404')
                }
                else {

                    setData(res.data.col)
                    setAvatar(res.data.col[0].userAvatar)
                    setUsername(res.data.col[0].userName)
                    setUserId(res.data.col[0].userId)
                    setCollectionName(res.data.col[0].colName)
                    setIsLoading(false)

                }

            })
            .catch(err => console.log(err.response));
    }, [id, navigate])
    return (<>

      

        {isLoading ? <CollectionSingleSkeleton /> : <>
        <section className="collection-banner">
            <div className="container">
                <div className="collection-bnr-wrapper">
                    <div className="collection-brn-content text-center">
                        <h2>{collectionName} Collection</h2>
                        <Link className="col-author" to={`/author/${userId}`}>
                            <img src={`https://images.artsfields.com/avatar/${avatar}`} alt="Author" />
                            <span>{username}</span></Link>
                    </div>
                </div>
            </div>
        </section>

            <section className="explore-section padding-top padding-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="explore-wrapper auction-loadmore">
                            <div className="row g-4">
                                <SingleCollection data={data} />
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>}







    </>);
}

export default CollectionSingle;