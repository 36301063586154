import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { NFTCurrentImageMini, NFTImageThumbnail } from "../../style/bodyStyle";
import CategorySingleSkeleton from "../skeleton/categorySingle";

const CategorySingle = () => {
    const param = useParams()
    const id = param.id
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        axios.post("https://api.artsfields.com/admin/categoryId", { id: id }).then(res => {
            // console.log(res.data.info)
            setData(res.data.info)
            setIsLoading(false)
        })
            .catch(err => {
                console.log(err.response)
            })
    }, [id])
    const encryperate = (image) => (`data:image/gif;base64,${image}`)
    const itemName = (name) => {
        if (name.length < 15) {
            return name
        }
        else {
            return name.substring(0, 15) + "..."
        }
    }

    return (<>
        <section className="explore-section  padding-top padding-bottom">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row gy-5 flex-row-reverse">

                        <div className="col-lg-9">
                            <div className="explore-wrapper explore-load">
                                <div className="row g-4">
                                    {isLoading ? <CategorySingleSkeleton /> :
                                        data.map((item) => {
                                            return (
                                                <div className="col-xl-4 col-md-6" key={item.nftId}>
                                                    <div className="nft-item">
                                                        <div className="nft-inner">

                                                            <div className="nft-item-top d-flex justify-content-between align-items-center">
                                                                <div className="author-part">
                                                                    <ul className="author-list d-flex">
                                                                        <li className="single-author d-flex align-items-center">
                                                                            <Link to={`/author/${item.authorId}`}
                                                                                className="veryfied">
                                                                                <img
                                                                                    src={`https://images.artsfields.com/avatar/${item.authorAvater}`}
                                                                                    alt="author-img" /></Link>
                                                                            <h6><Link
                                                                                to={`/author/${item.authorId}`}>{item.authorName}</Link>
                                                                            </h6>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="more-part">
                                                                    <div className=" dropstart">
                                                                        <Link className=" dropdown-toggle"
                                                                            to="#" role="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                            data-bs-offset="25,0">
                                                                            <i
                                                                                className="icofont-flikr"></i>
                                                                        </Link>

                                                                        <ul className="dropdown-menu">
                                                                            <li><Link className="dropdown-item"
                                                                                to="#"><span>
                                                                                    <i
                                                                                        className="icofont-warning"></i>
                                                                                </span> Report </Link>
                                                                            </li>
                                                                            <li><Link className="dropdown-item"
                                                                                to="#"><span><i
                                                                                    className="icofont-reply"></i></span>
                                                                                Share</Link></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="nft-item-bottom">
                                                                <div className="nft-thumb">
                                                                    <NFTImageThumbnail src={encryperate(item.nftThumbnail)} alt="nft-img" />



                                                                    <span className="badge rounded-pill position-absolute"><i className="icofont-heart"></i>
                                                                        1.3k</span>
                                                                </div>
                                                                <div className="nft-content">
                                                                    <div className="content-title">
                                                                        <h5><Link to={`/nft/${item.nftId}`}>{itemName(item.nftName)}</Link>
                                                                        </h5>
                                                                    </div>

                                                                    <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">

                                                                        <div className="nft-stock"> {item.inStock} in Stock</div>
                                                                    </div>
                                                                    <div className="price-like d-flex justify-content-between align-items-center">
                                                                        <div className="nft-price d-flex align-items-center">
                                                                            <span className="currency-img">
                                                                                <NFTCurrentImageMini src={require(`../../images/currency/SVG/${item.currency.toUpperCase()}.svg`)} alt="currency_img" />
                                                                            </span>
                                                                            <p>{item.price} {item.currency.toUpperCase()}
                                                                            </p>
                                                                        </div>

                                                                        <Link to={`/nft/${item.nftId}`} className="nft-bid">Place Bid</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <aside className="nft-filter">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                <i className="icofont-atom"></i> Chain
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="ethereum" />
                                                    <label className="form-check-label" htmlFor="ethereum">
                                                        Ethereum
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="bsc" defaultChecked />
                                                    <label className="form-check-label" htmlFor="bsc">
                                                        BSC
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="polygone" />
                                                    <label className="form-check-label" htmlFor="polygone">
                                                        Polygone
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="cronos" />
                                                    <label className="form-check-label" htmlFor="cronos">
                                                        Cronos
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                <i className="icofont-ui-browser"></i> Status
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse"
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="status1" />
                                                    <label className="form-check-label" htmlFor="status1">
                                                        For Sell
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="status2" />
                                                    <label className="form-check-label" htmlFor="status2">
                                                        For Collect
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="status3" />
                                                    <label className="form-check-label" htmlFor="status3">
                                                        Trending
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                <i className="icofont-library"></i> Collections
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse"
                                            aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect1" />
                                                    <label className="form-check-label" htmlFor="collect1">
                                                        Dark Cat Mewo
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect2" />
                                                    <label className="form-check-label" htmlFor="collect2">
                                                        Space_Riderz
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect3" />
                                                    <label className="form-check-label" htmlFor="collect3">
                                                        Mimi_chika chao
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect4" />
                                                    <label className="form-check-label" htmlFor="collect4">
                                                        Trending_Sculpture
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect5" />
                                                    <label className="form-check-label" htmlFor="collect5">
                                                        Happy_hellwon
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect6" />
                                                    <label className="form-check-label" htmlFor="collect6">
                                                        Rock dA_ Crypto
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect7" />
                                                    <label className="form-check-label" htmlFor="collect7">
                                                        Trdzng_Sxpture
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect8" />
                                                    <label className="form-check-label" htmlFor="collect8">
                                                        Mickao_Fly'n re
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="collect9" />
                                                    <label className="form-check-label" htmlFor="collect9">
                                                        Coffiinnee_de Mritto
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>);
}

export default CategorySingle;