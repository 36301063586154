import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone'
import { LoadingButton } from "../components/loading";
import { DangerAlert, SuccessAlert, Text } from "../../style/generalStyle";
import { FlexList, Ml5, Mr5 } from "../../style/bodyStyle";


const CreateNFT = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [itemName, setItemName] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemImage, setItemImage] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [itemOwner, setItemOwner] = useState("");
    const [itemCoin, setItemCoin] = useState("eth");
    const [itemQuantity, setItemQuantity] = useState("");
    const [itemCategory, setItemCategory] = useState("");
    const [itemSize, setItemSize] = useState("");
    const [itemType, setItemType] = useState("");
    const [imageName, setImageName] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [regResponse, setRegResponse] = useState("")
    
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
       setItemImage(acceptedFiles)  
       setItemSize(acceptedFiles[0].size)
       setItemType(acceptedFiles[0].type)
       setImageName(<><div className="uploaded">{acceptedFiles[0].name}  - {acceptedFiles[0].size} bytes</div></>)
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    useEffect(() => {
        if (Cookies.get("__Logged") === undefined || Cookies.get("__Logged") === "") {
            navigate("/login");
        }
        else {
            setItemOwner(Cookies.get("__Logged"));
            axios.post("https://api.artsfields.com/admin/allCategories", {}).then(res => {
                // console.log(res.data.info)
               
                setData(res.data.info)
            })
                .catch(err => {
                    console.log(err.response)
                })
        }

    }, [setData, navigate]);
    const encryperate = (image) => (`data:image/png;base64,${image}`)

    const CreateNFT = (e) => {
        e.preventDefault()
        setIsLoading(true)
        setRegResponse("")
        var formData = new FormData();
        var file1 = itemImage[0];
        formData.append("image", file1, file1.name);
        axios.post("https://api.artsfields.com/document/uploadNFTs.php", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                axios.post("https://api.artsfields.com/user/createNFT",
                    {
                        name: itemName,
                        description: itemDescription,
                        image: res.data.generatedId,
                        price: itemPrice,
                        owner: itemOwner,
                        coin: itemCoin,
                        quantity: itemQuantity,
                        category: itemCategory,
                        size: itemSize,
                        fileType:itemType
                    })
                    .then(res => {
                      

                        console.log(res.data)
                        //navigate("/");
                    }).catch(err => {
                        setRegResponse(<DangerAlert>Upload Error. Please Check that all parameters are correct</DangerAlert>)
                        setIsLoading(false)
                        setTimeout(() => {
                            setRegResponse("")
                        }, 5000)
                        console.log(err.response)
                    })
                .finally(() => {
                    setRegResponse(<SuccessAlert>NFT Created Successfully</SuccessAlert>)
                        setTimeout(() => {
                            setRegResponse("")
                        }, 5000)
                    setIsLoading(false)
                    setItemName("");
                    setItemDescription("");
                    setItemImage("");
                    setItemPrice("");
                    setItemQuantity("");
                    setItemCategory("");
                    setItemSize("");
                    setImageName("");
                    setActiveId("");
                    setItemType('')
                })

            })
            .catch(err => {
                console.log(err.response)
            })


    }
    const processActive = (e) => {
        setActiveId(e.target.id);
    }
    const [activeId, setActiveId] = useState();
    return (<>

        <section className="create-nft-section padding-bottom padding-top">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="create-nft py-5 px-4">    
                            <Text size={10} color={'#ff0000'} >* Required fields</Text> 
                                <form className="create-nft-form" onSubmit={CreateNFT}>
                                <Text size={10} >File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 50 MB</Text>
                                <div className="upload-item mb-30"> 
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragActive ?
                                                <p>Drop the files here ...</p> :
                                               <><p>Drag 'n' drop Your NFT here, or click to select files</p>
                                               </> 
                                        }
                                        </div>
                                    </div>
                                   {imageName}

                                    <div className="form-floating item-name-field mb-3">
                                        <input type="text" className="form-control" id="itemNameInput" placeholder="Item Name" value={itemName} onChange={(e) => { setItemName(e.target.value) }} />
                                        <label htmlFor="itemNameInput">
                                            Name <Text size={15} color={'#ff0000'} >*</Text> </label>
                                    </div>

                                    <div className="form-floating item-desc-field mb-30">
                                        <textarea className="form-control" placeholder="Item Description"
                                            id="itemDesc" value={itemDescription} onChange={(e) => { setItemDescription(e.target.value) }} ></textarea>
                                        <label htmlFor="itemDesc">
                                            Description</label>
                                    </div>

                                    <div className="item-category-field mb-30">
                                        <h4>Select Item Catergory</h4>
                                        <ul className="item-cat-list d-flex flex-wrap">
                                            {data && data.map((item) => (

                                                <FlexList className={`item-cat-btn ${activeId === item.id && 'active'}`} key={item.id} onClick={(e) => { setItemCategory(e.target.id); processActive(e) }} id={item.id}>
                                                    <span  onClick={(e) => { setItemCategory(e.target.id); processActive(e) }} id={item.id}> 
                                                    <img src={encryperate(item.symbol)} alt="Category_Image" width="20px"  onClick={(e) => { setItemCategory(e.target.id); processActive(e) }} id={item.id}/>
                                                    </span>
                                                    <Ml5  onClick={(e) => { setItemCategory(e.target.id); processActive(e) }} id={item.id}>{item.name}</Ml5>
                                                </FlexList>


                                            ))}
                                        </ul>
                                    </div>

                                    <div className="item-price-field mb-3">
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <select className="form-select" id="selectCrypto"
                                                        aria-label="Floating label select" onChange={(e) => { setItemCoin(e.target.value) }}>
                                                        <option value="eth">
                                                            Ethereum
                                                        </option>
                                                        <option value="btc">
                                                            BitCoin
                                                        </option>
                                                        <option value="usdt">
                                                          Tetar (USDT)
                                                        </option>
                                                        <option value="bnb">
                                                            Binance Coin
                                                        </option>
                                                    </select>
                                                    <label htmlFor="selectCrypto">Select
                                                        Currency</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="itemPriceInput"
                                                        placeholder="Item Price" onChange={(e) => { setItemPrice(e.target.value) }} value={itemPrice} />
                                                    <label htmlFor="itemPriceInput">Item
                                                        Price</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item-price-field mb-5">
                                        <div className="row g-3 justify-content-center">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="royalityInput"
                                                        placeholder="Royalities" onChange={(e) => { }} />
                                                    <label htmlFor="royalityInput">Royalities</label>
                                                </div>
                                            </div>
                                       
                                            <div className="col-md-6 col-lg-6">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="itemNumbersInput"
                                                        placeholder="Number of Supply" onChange={(e) => { setItemQuantity(e.target.value) }} value={itemQuantity} />
                                                    <label htmlFor="itemNumbersInput">Number
                                                        of Supply</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="submit-btn-field text-center">
                                    {regResponse}
                                    <div className="form-group">
                                    {isLoading ?   <button className="disabled" disabled={true}  type="submit"><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> 
                                            : <button type="submit">Create Item</button>}
                                        
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>


    </>);
}

export default CreateNFT;