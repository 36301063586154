import { Footer, FooterNewletter, FooterTop } from "./style/footerStyle";

const FooterDiv = () => {
    return ( <>
     <Footer>
        <FooterTop className="footer-top" >
            <FooterNewletter >
                <div className="container">
                    <div className="row g-4 align-items-center justify-content-center">
                        <div className="col-lg-6">
                            <div className="newsletter-part">
                                <div className="ft-header">
                                    <h4>Get The Latest Artsfields Updates</h4>
                                </div>
                                <form action="#">
                                    <input type="email" placeholder="Your Mail Address"/>
                                    <button type="submit"> Subscribe now</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="social-part ps-lg-5">
                                <div className="ft-header">
                                    <h4>Join the Community</h4>
                                </div>
                                <ul className="social-list d-flex flex-wrap align-items-center mb-0">
                                    <li className="social-link"><a href="#"><i className="icofont-twitter"></i></a></li>
                                    <li className="social-link"><a href="#"><i className="icofont-twitch"></i></a></li>
                                    <li className="social-link"><a href="#"><i className="icofont-reddit"></i></a></li>
                                    <li className="social-link"><a href="#"><i className="icofont-instagram"></i></a></li>
                                    <li className="social-link"><a href="#"><i className="icofont-dribble"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


            </FooterNewletter>
            <div className="footer-links padding-top padding-bottom">
                <div className="container">
                    <div className="row g-5 row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
                        <div className="col">
                            <div className="footer-link-item">
                                <h5>About</h5>
                                <ul className="footer-link-list">
                                    <li><a href="#" className="footer-link">Explore</a></li>
                                    <li><a href="#" className="footer-link">How it works</a></li>
                                    <li><a href="#" className="footer-link">Support</a></li>
                                    <li><a href="#" className="footer-link">Become a partner</a></li>

                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-link-item">
                                <h5>NFT Marketplace</h5>
                                <ul className="footer-link-list">
                                    <li><a href="#" className="footer-link">Sell your assets</a></li>
                                    <li><a href="#" className="footer-link">FAQ</a></li>
                                    <li><a href="#" className="footer-link">Support</a></li>
                                    <li><a href="#" className="footer-link">Privacy/Policy</a></li>
                                    <li><a href="#" className="footer-link">Your purchases</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-link-item">
                                <h5>Company</h5>
                                <ul className="footer-link-list">
                                    <li><a href="#" className="footer-link">About</a></li>
                                    <li><a href="#" className="footer-link">Mission & Team</a></li>
                                    <li><a href="#" className="footer-link">Our Blog</a></li>
                                    <li><a href="#" className="footer-link">Services</a></li>
                                    <li><a href="#" className="footer-link">We're Hiring</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-link-item">
                                <h5>NFT Marketplace</h5>
                                <ul className="footer-link-list">
                                    <li><a href="#" className="footer-link">Sell your assets</a></li>
                                    <li><a href="#" className="footer-link">FAQ</a></li>
                                    <li><a href="#" className="footer-link">Support</a></li>
                                    <li><a href="#" className="footer-link">Privacy/Policy</a></li>
                                    <li><a href="#" className="footer-link">Your purchases</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-link-item">
                                <h5>Comunity</h5>
                                <ul className="footer-link-list">
                                    <li><a href="#" className="footer-link">NFT Token</a></li>
                                    <li><a href="#" className="footer-link">Discusion</a></li>
                                    <li><a href="#" className="footer-link">Voting</a></li>
                                    <li><a href="#" className="footer-link">Suggest Feature</a></li>
                                    <li><a href="#" className="footer-link">Language</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FooterTop>
        <div className="footer-bottom">
            <div className="container">
                <p className="text-center py-4 mb-0">All rights reserved &copy; Artsfields
                </p>
            </div>
        </div>

    </Footer>
   </> );
}
 
export default FooterDiv;