import axios from "axios";
import {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InputError, SuccessAlert } from "../style/generalStyle";
import { LoadingButton } from "./components/loading";


const Signup = () => {
    const [isMobile, setIsMobile] = useState(true)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [avatar, setAvatar] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [validateUser, setValidateUser] = useState("")
    const [validateEmail, setValidateEmail] = useState("")
    const [validatePassword, setValidatePassword] = useState("")
    const [validateConfirmPassword, setValidateConfirmPassword] = useState("")
    const [regResponse, setRegResponse] = useState("")


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            }
            else if (window.innerWidth < 767) {
                setIsMobile(true)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [isMobile])

    const EmailValidation = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        document.getElementById("confirmPass").classList.remove("inputError")
        document.getElementById("floatingPassword").classList.remove("inputError")
        document.getElementById("floatingInput").classList.remove("inputError")
        document.getElementById("userIdInput").classList.remove("inputError")
        setValidateConfirmPassword("")
        setValidatePassword("")
        setValidateEmail("")
        setValidateUser("")
        const check = EmailValidation(email)
        if (username === "") {
            document.getElementById("userIdInput").classList.add("inputError")
            setValidateUser(<><InputError>* Username is empty</InputError></>)
            setIsLoading(false)
        }
        else if (check === null) {
            document.getElementById("floatingInput").classList.add("inputError")
            setValidateEmail(<><InputError>* Invalid email</InputError></>)
            setIsLoading(false)
        }
        else if (email === "") {
            document.getElementById("floatingInput").classList.add("inputError")
            setValidateEmail(<><InputError>* Email is empty</InputError></>)
            setIsLoading(false)
        }

        else if (password === "") {
            document.getElementById("floatingPassword").classList.add("inputError")
            setValidatePassword(<><InputError>* Password is empty</InputError></>)
            setIsLoading(false)
        }
        else if (password !== confirmPassword) {
            document.getElementById("confirmPass").classList.add("inputError")
            document.getElementById("floatingPassword").classList.add("inputError")
            setValidateConfirmPassword(<><InputError>* Password Mismatched</InputError></>)
            setIsLoading(false)
        }
        else {
            let avatarId = username
            fetch('https://api.multiavatar.com/'+avatarId)
            .then(res => res.text())
            .then(svg => {
                axios.post(`https://api.artsfields.com/document/uploadAvatar.php`,{svg:svg})
                .then(res=>{ 
                    console.log(res.data)
                    setAvatar(res.data.name)

                    //////////////////////////////////////////////////
                    axios.post("https://api.artsfields.com/auth/register",
                    {
                        username: username,
                        password: password,
                        email: email,
                        avatar:res.data.name
                    })
                    .then(response => {
                        const userId = response.data.userId
                        const type = "reg"
                        const time = Math.floor(Date.now() / 1000)
                        const expires = Math.floor(time + 172800)  //2 hours
                        axios.post('https://api.artsfields.com/auth/createToken', {
                            email: email,
                            expires: expires,
                            type: type,
                            userId: userId
                        })
                            .then((response) => {
                                const token = response.data.token
                                axios.post(`https://api.artsfields.com/mailer/verificationemail`, {
                                    email: email,
                                    token: token,
                                    userId: userId
                                })
                                    .then(() => {
                                        setIsLoading(false)
                                        setRegResponse(<SuccessAlert>Registration Succesful kindly check your email address for verification. <span className="d-block cate pt-10">Did'nt recieve an email?<Link to="#" onClick={(e)=>{e.preventDefault();resendRequest(userId,token)}}>Resend Mail</Link></span></SuccessAlert>)
                                    })
                                    .catch((error) => {
                                        console.log(error.response)
                                    })
                                // navigate('/auth', {state: {user:response.data.user, messageId:messageId}})
                            })
    
                    })
                    .catch(error => {
                        console.log(error.response)
                        if (error.response.status === 400) {
                            setValidateUser(<><InputError>* Username already exists</InputError></>)
                            setIsLoading(false)
                        }
                    })
                })
            })

          
        }
    }

    const resendRequest = (userId, token) => {
        setIsLoading(true)
        setRegResponse("")
        axios.post(`https://api.artsfields.com/mailer/verificationemail`, {
            email: email,
            token: token,
            userId: userId
        })
            .then(() => {
                setIsLoading(false)
                setRegResponse(<SuccessAlert>Registration Succesful kindly check your email address for verification. <span className="d-block cate pt-10">Did'nt recieve an email?<Link to="#" onClick={resendRequest}>Resend Mail</Link></span></SuccessAlert>)
            })
            .catch((error) => {
                console.log(error.response)
            })


    }

    return (<>
        <div className="login-section padding-top padding-bottom">
            <div className=" container">
                <div className="row g-5 align-items-center flex-md-row-reverse">
                    <div className="col-lg-5">
                        <div className="account-wrapper">
                            <h3 className="title">Sign Up</h3>

                            <form className="account-form" onSubmit={handleSubmit}>
                                <div className="form-floating mb-3" id="userIdInput" >
                                    <input type="text" className="form-control"
                                        placeholder="user-id" onChange={(e) => setUsername(e.target.value)} />
                                    <label htmlFor="userIdInput">Username</label>
                                    {validateUser}
                                </div>
                                <div className="form-floating mb-3" id="floatingInput">
                                    <input type="email" className="form-control"
                                        placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor="floatingInput">Email address</label>
                                    {validateEmail}
                                </div>
                                <div className="form-floating mb-3" id="floatingPassword">
                                    <input type="password" className="form-control"
                                        placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    <label htmlFor="floatingPassword">Password</label>
                                    {validatePassword}
                                </div>
                                <div className="form-floating mb-3" id="confirmPass">
                                    <input type="password" className="form-control"
                                        placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                                    <label htmlFor="confirmPass">Confirm Password</label>
                                    {validateConfirmPassword}
                                </div>
                                <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="remember" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                        <Link to="forgot-pass">Forgot Password?</Link>
                                    </div>
                                </div>
                                {regResponse}
                                <div className="form-group">
                                    {isLoading ? <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> :
                                        <>  <button className="d-block default-btn move-top"><span>Signup Now</span></button></>}
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Already Have an Account? <Link to="/login"> Sign
                                    In</Link></span>
                            </div>
                        </div>
                    </div>
                    {isMobile ? "" :
                        <div className="col-lg-7">
                            <div className="account-img">
                                <img src={require(`../images/account/01.png`)} alt="shape_image" />
                            </div>
                        </div>}
                </div>

            </div>
        </div>
    </>);
}

export default Signup;