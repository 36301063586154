import { Link } from "react-router-dom";
import { BannerSection, BannerWrapper, Container } from "../../style/bodyStyle";
import { TopBannerSwipper } from "../components/Swipers";

const HomeBanner = () => {    
    return ( <>
    <BannerSection>
            <Container>
                <BannerWrapper>
                    <div className="row align-items-center g-5">
                        <div className="col-lg-8">
                            <div className="banner-content">
                                <h1 className="mb-3"><span className="gradient-text-orange">Discover</span> , Collect, And Sell
                                    <span className="gradient-text-orange">
                                        NFTs</span>
                                </h1>
                                <p className="mb-5">Digital Marketplace For Crypto Collectibles And Non-Fungible Tokens.
                                    Buy, Sell, And Discover Exclusive Digital Assets.</p>
                                <div className="banner-btns d-flex flex-wrap">
                                    <Link to="/explore" className="default-btn move-top"><span>Explore</span> </Link>
                                    <Link to="/create" className="default-btn move-right"><span>Create</span> </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <TopBannerSwipper/>
                        </div>
                    </div>
                </BannerWrapper>
            </Container>
        </BannerSection>
    </> );
}
 
export default HomeBanner;