import { Link } from "react-router-dom";

const AuthorHeader = ({data}) => {
    return ( <>
     <div className="profile-item">
                        <div className="profile-cover">
                            <img src={require(`../../images/profile/cover-2.jpg`)} alt="cover-pic"/>
                        </div>
                        <div className="profile-information">
                            <div className="profile-pic">
                                <img src={`https://images.artsfields.com/avatar/${data.userAvatar}`} alt="DP"/>
                            </div>
                            <div className="profile-name">
                                <h4>{data.userName}</h4>
                                <p>Joined October 2022</p>
                            </div>
                            <ul className="profile-contact">
                                <li className="crypto-copy">
                                    <div id="cryptoCode" className="crypto-page">
                                        <input id="cryptoLink" value={`${data.walletAddress}`} readOnly/>
                                        <div id="cryptoCopy" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Copy Address">
                                            <span className="copy-icon">
                                                <i className="icofont-ui-copy" aria-hidden="true"
                                                    data-copytarget="#cryptoLink"></i>
                                            </span>

                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <Link to="#">
                                        <div className="icon"><i className="icofont-ui-rate-add"></i></div>
                                        <div className="text">
                                            <p>Follow</p>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <div className="icon"><i className="icofont-speech-comments"></i></div>
                                        <div className="text">
                                            <p>Send Message</p>
                                        </div>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                  </> );
}
 
export default AuthorHeader;