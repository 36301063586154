import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Signout = () => {
    const navigate = useNavigate();
    useEffect(() => {
     Cookies.remove('__Logged')
     window.location.reload(false)
     navigate("/login")
    })
   
    return ( <></> );
}
 
export default Signout;