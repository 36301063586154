import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CollectionsTopBanner from "./collections/topBanner";
import { CollectionList } from "./components/Swipers";
import CollectionSkeleton from "./skeleton/collections";

const NFTCollections = () => {
const [collection, setCollection] = useState([])
const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        axios.post('https://api.artsfields.com/getData/getCollections',{})
        .then((res) => {  
           // console.log(res.data)
            setCollection(res.data.col)
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err.response.data)
        })

    }, [])
    return (<>
<CollectionsTopBanner/>

<section className="collection-section padding-bottom">
        <div className="container">
            <div className="section-wrapper">
                <div className="collection-wrapper">
                    <div className="row justify-content-center g-4">
                   {isLoading ? <> <CollectionSkeleton/> </>:<>
                   
                     {collection && collection.map(item => (
            <div className="col-md-6 col-lg-4" key={item.collectionId}>
                <div className="nft-item collection-item">
                    <div className="nft-inner">
                        <div className="coll-thumb-wrapper">
                            <CollectionList data={item.nfts} />
                        </div>
                        <div className="nft-content">
                            <div className="collection-title">
                                <h4><Link to={`/collections/${item.collectionId}`}>{item.collectionName}</Link> </h4>
                                <p>Collection has {item.nfts.length} items</p>
                            </div>

                            <div
                                className="author-details d-flex flex-wrap justify-content-between align-items-center">
                                <div
                                    className="single-author d-flex align-items-center">
                                    <Link to={`/author/${item.userId}`} className="veryfied"><img
                                        src={`https://images.artsfields.com/avatar/${item.userAvatar}`} alt="author-img" /></Link>
                                    <h6><Link to={`/author/${item.userId}`}>{item.userName}</Link></h6>
                                </div>
                                <span className="badge rounded-pill"><i
                                    className="icofont-heart"></i>
                                    11.3k</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
                   
                   </>}    
      
 </div>
                        </div>
                        </div>
            </div>
            </section>

    </>);
}
 
export default NFTCollections;