import axios from "axios";
import Cookies from "js-cookie";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlexColumn } from "../../style/bodyStyle";
import { Calculaion, DangerAlert, SuccessAlert } from "../../style/generalStyle";
import { PairExchange } from "../components/coinExchange";
import { LoadingButton } from "../components/loading";

const PurchaseToken = ({data, id, units})=>{
    const navigate = useNavigate()
    const [regResponse, setRegResponse] = useState('')
  const [userBalance, setUserBalance] = useState([])
 

    const closeModal = (() => {
        let modal = document.getElementById("myModal");
        modal.style.display = "none";
        modal.style.transition = "1s ease-out";
    })
    
const balance = useCallback(()=>{
    const userId= JSON.parse(localStorage.getItem('__LUD')).userId
    axios.post(`https://api.artsfields.com/user/userBalance`,{userId: userId})
    .then((res)=>{
        setUserBalance(res.data.wallet)
       // console.log(res.data.wallet)
    })
},[]) 

    useEffect(()=>{ 
        balance()
    },[balance])

    const [isBidLoading, setIsBidLoading] = useState(false)
    const [itemUnits, setItemUnits] = useState("1")
    const [totalBalance, setTotalBalance] = useState(data.price)
    const buyNFT = (e) => {
        e.preventDefault()
        if (Cookies.get("__Logged") === undefined || Cookies.get("__Logged") === "") {
            navigate("/login");
        }
        else {
            let isnum = /^\d+\.\d+$/.test(itemUnits);
            let isdob = /^\d+$/.test(itemUnits);
            let checkP = Number(data.price * itemUnits) <= Number(userBalance.balance)
            let checkUnits = Number(itemUnits) <= Number(units)
           
            console.log(checkUnits, checkP, isdob, isdob)
           
            if ((isnum || isdob ) && checkP && checkUnits) {
                setIsBidLoading(true)
                const nftId = id
                const userId = JSON.parse(localStorage.getItem('__LUD')).userId
                const payload = {
                    ownerId: data.userId,
                    userId: userId,
                    nftId: nftId,
                    price: data.price,                   
                    units: itemUnits                                                                                                       
                }
                // console.log(payload)
                // console.log(data.userId)
                axios.post("https://api.artsfields.com/user/buyNFT", payload)
                    .then((res) => {
                        console.log(res)
                        setRegResponse(<SuccessAlert>Token Purchased</SuccessAlert>)
                        balance()
                    })
                    .catch((err) => {
                        if (err.response.status === 420) {
                            setRegResponse(<DangerAlert>Insufficient Balance</DangerAlert>)
                        }

                        else {
                            setRegResponse(<DangerAlert>Sorry an error occured </DangerAlert>)
                        }
                        console.log(err.response)

                    })
                    .finally(() => {
                        setInterval(() => {
                            setRegResponse('')
                            //  setItemUnits("")
                        }, 5000);

                        setIsBidLoading(false)
                    })
            }
            else {

                setRegResponse(<DangerAlert>Invalid Input</DangerAlert>)
                setInterval(() => {
                    setRegResponse('')
                    //  setItemUnits("")
                }, 5000);
            }
        }
    }
    return (<>
 
               
    <div className="modal-header">
            <div className="modal-title">
                Buy Token
            </div>
            <span className="close" onClick={closeModal}>&times;</span>
        </div>
        <div className="modal-body">
        <p>Available Units: <b>{units}</b></p>
            <p>Current Sales Price: <b>{data.price} {(`${data.currency}`).toUpperCase()}
                ($<PairExchange currency="USD" coin={data.currency} price={data.price} />)</b></p>
            <p>Your Current Balance: <b>{userBalance.balance} {(`${data.currency}`).toUpperCase()}
                ($<PairExchange currency="USD" coin={userBalance.coinShort} price={userBalance.balance} />)</b></p>
            <form className="account-form" onSubmit={buyNFT}>
            <div className="form-floating mb-3" id="enterUnits">
                    <input type="text" className="form-control" placeholder="token Units" value={itemUnits} onChange={(e) => {setItemUnits(e.target.value); setTotalBalance((Number(e.target.value)* Number(data.price)).toFixed(6))}} />
                    <label htmlFor="enterUnits">Token Units</label> 
                </div>
                {regResponse}
                <FlexColumn className="form-group">
                    {isBidLoading ? <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> :
                        <>  <button className="d-block default-btn move-top" type="submit"><span>Buy Token</span></button></>}
                        <Calculaion>{itemUnits} x {units} = {totalBalance}ETH </Calculaion>
                </FlexColumn>

            </form>
        </div>

    </>)
}
export default PurchaseToken;