import { MainImage, Pill, Text } from './styles'
const CategorySingleSkeleton = () => {
    return (<>

        <section className="explore-section padding-top padding-bottom">
            <div className="container">
                <div className="section-wrapper">
                    <div className="explore-wrapper auction-loadmore">
                        <div className="row g-4">
        <div className="col-xl-4 col-md-6">
            <div className="nft-item">
                <div className="nft-inner">
  
                    <div className="nft-item-top d-flex justify-content-between align-items-center">
                        <div className="author-part">
                            <ul className="author-list d-flex">
                                <li className="single-author d-flex align-items-center">
                                    <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </li>
                            </ul>
                        </div>
                        <div className="more-part">
                            <div className=" dropstart">
                                <Text height="10" width="20" radius="10"></Text>
                            </div>
                        </div>
                    </div>

                    <div className="nft-item-bottom">
                        <div className="nft-thumb">
                            <MainImage></MainImage>
                            <Pill></Pill>
                        </div>
                        <div className="nft-content">
                            <div className="content-title">
                                <Text height="25" width="100" radius="10"></Text>
                            </div>
                            <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                <Text height="17" width="50" radius="10"></Text>
                            </div>
                            <div className="price-like d-flex justify-content-between align-items-center">
                                <div className="nft-price d-flex align-items-center">
                                    <span className="currency-img">
                                        <Text height="25" width="25" radius="500"></Text>
                                    </span>
                                    <Text height="17" width="100" radius="10"></Text>
                                </div>
                                <Text height="25" width="60" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-4 col-md-6">
            <div className="nft-item">
                <div className="nft-inner">

                    <div className="nft-item-top d-flex justify-content-between align-items-center">
                        <div className="author-part">
                            <ul className="author-list d-flex">
                                <li className="single-author d-flex align-items-center">
                                    <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </li>
                            </ul>
                        </div>
                        <div className="more-part">
                            <div className=" dropstart">
                                <Text height="10" width="20" radius="10"></Text>
                            </div>
                        </div>
                    </div>

                    <div className="nft-item-bottom">
                        <div className="nft-thumb">
                            <MainImage></MainImage>
                            <Pill></Pill>
                        </div>
                        <div className="nft-content">
                            <div className="content-title">
                                <Text height="25" width="100" radius="10"></Text>
                            </div>
                            <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                <Text height="17" width="50" radius="10"></Text>
                            </div>
                            <div className="price-like d-flex justify-content-between align-items-center">
                                <div className="nft-price d-flex align-items-center">
                                    <span className="currency-img">
                                        <Text height="25" width="25" radius="500"></Text>
                                    </span>
                                    <Text height="17" width="100" radius="10"></Text>
                                </div>
                                <Text height="25" width="60" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-xl-4 col-md-6">
            <div className="nft-item">
                <div className="nft-inner">

                    <div className="nft-item-top d-flex justify-content-between align-items-center">
                        <div className="author-part">
                            <ul className="author-list d-flex">
                                <li className="single-author d-flex align-items-center">
                                    <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </li>
                            </ul>
                        </div>
                        <div className="more-part">
                            <div className=" dropstart">
                                <Text height="10" width="20" radius="10"></Text>
                            </div>
                        </div>
                    </div>

                    <div className="nft-item-bottom">
                        <div className="nft-thumb">
                            <MainImage></MainImage>
                            <Pill></Pill>
                        </div>
                        <div className="nft-content">
                            <div className="content-title">
                                <Text height="25" width="100" radius="10"></Text>
                            </div>
                            <div className="nft-status d-flex flex-wrap justify-content-between align-items-center ">
                                <Text height="17" width="50" radius="10"></Text>
                            </div>
                            <div className="price-like d-flex justify-content-between align-items-center">
                                <div className="nft-price d-flex align-items-center">
                                    <span className="currency-img">
                                        <Text height="25" width="25" radius="500"></Text>
                                    </span>
                                    <Text height="17" width="100" radius="10"></Text>
                                </div>
                                <Text height="25" width="60" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

</div>
</div>
</div>
</section>

    </>);
}

export default CategorySingleSkeleton;