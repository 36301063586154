import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CollectionSkeleton from "./skeleton/collections";
import ProfileCollection from "./user/collecttion";
import ProfileCreated from "./user/created";
import ProfileOnSale from "./user/onsale";
import ProfileOwned from "./user/owned";
import UserHeader from "./user/profileHeader";

const UserAccount = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [userInfo, setUserInfo] = useState([])
    const [auctions, setAuctions] = useState([])
    const [collection, setCollection] = useState([])
    const [owned, setOwned] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        if (Cookies.get("__Logged") === undefined) {
            navigate("/login");
        }
        else {
            const user = JSON.parse(localStorage.getItem('__LUD'));
            const userId = user.userId
            axios.post(`https://api.artsfields.com/user/userNFTs`, { userId: userId })
                .then((res) => {
                    setData(res.data.info)
                })
                .catch((err) => {
                    console.log(err.response.data);
                })

            axios.post(`https://api.artsfields.com/user/getUserLiveAuction`, { userId: userId })
                .then((res) => {
                    setAuctions(res.data.info)
                })
                .catch((err) => {
                    console.log(err.response.data);
                })

            axios.post('https://api.artsfields.com/user/userInfo', { userId: userId })
                .then((res) => {
                    setUserInfo(res.data.user)
                })
                .catch((err) => {
                    console.log(err.response.data);
                })

            axios.post('https://api.artsfields.com/user/userCollections', { userId: userId })
                .then((res) => {
                    setCollection(res.data.col)
                    setIsLoading(false)
                })
                .catch(err => {
                    console.log(err.response.data)
                })

                
            axios.post('https://api.artsfields.com/user/userOwned', { userId: userId })
            .then((res) => {
                console.log(res)
                setOwned(res.data.info)
            })
            .catch(err => {
                console.log(err.response.data)
            })
        }
    }, [navigate])

    const [collValue, setCollValue] =useState('')
    const [collDIs, setCollDIs] =useState('')

    const createCollection =()=>{
        const user = JSON.parse(localStorage.getItem('__LUD'));
        const userId = user.userId
        if (collValue.length > 0) {
console.log(collValue)
        }

        // axios.post('https://api.artsfields.com/user/userInfo', { userId: userId })
        // .then((res) => {
        //     setUserInfo(res.data.user)
        // })
        // .catch((err) => {
        //     console.log(err.response.data);
        // })
    }
    // const log = Cookies.get('__Logged')
    //         if ( log !== undefined) {
    //             navigate("/login")
    //         }
    return (<>
        <section className="profile-section padding-top padding-bottom">
            <div className="container">
                <div className="section-wrapper">
                    <div className="member-profile">
                        {userInfo && <UserHeader data={userInfo} />}
                        <div className="profile-details">
                            <nav className="profile-nav">
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button className="nav-link active" id="nav-allNft-tab" data-bs-toggle="tab"
                                        data-bs-target="#allNft" type="button" role="tab" aria-controls="allNft"
                                        aria-selected="true">All NFT's</button>
                                    <button className="nav-link" id="nav-about-tab" data-bs-toggle="tab" data-bs-target="#about"
                                        type="button" role="tab" aria-controls="about" aria-selected="false">About</button>
                                    <button className="nav-link" id="nav-activity-tab" data-bs-toggle="tab"
                                        data-bs-target="#activity" type="button" role="tab" aria-controls="activity"
                                        aria-selected="false">Activity</button>
                                    <button className="nav-link" id="nav-follower-tab" data-bs-toggle="tab"
                                        data-bs-target="#follower" type="button" role="tab" aria-controls="follower"
                                        aria-selected="false">Follower <span className="item-number">231</span></button>
                                    <button className="nav-link" id="nav-following-tab" data-bs-toggle="tab"
                                        data-bs-target="#following" type="button" role="tab" aria-controls="following"
                                        aria-selected="false">Following <span className="item-number">145</span></button>

                                    <div className="dropdown">
                                        <Link className="btn dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            Setting
                                        </Link>

                                        <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item" to="#">My Info</Link></li>
                                            <li><Link className="dropdown-item" to="#">Privacy</Link></li>
                                            <li><Link className="dropdown-item" to="#">Delete Account</Link></li>
                                        </ul>
                                    </div>

                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">

                                <div className="tab-pane activity-page fade show active" id="allNft" role="tabpanel">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-9">
                                                <article>
                                                    <div className="activity-tab">
                                                        <ul className="nav nav-pills mb-30 px-2" id="pills-tab" role="tablist">

                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link active" id="pills-mentions-tab"
                                                                    data-bs-toggle="pill" data-bs-target="#pills-mentions"
                                                                    type="button" role="tab" aria-controls="pills-mentions"
                                                                    aria-selected="true"><i className="icofont-flash"></i>
                                                                    On Auction</button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-favorites-tab"
                                                                    data-bs-toggle="pill" data-bs-target="#pills-favorites"
                                                                    type="button" role="tab" aria-controls="pills-favorites"
                                                                    aria-selected="false"><i className="icofont-license"></i>
                                                                    owned</button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-groups-tab"
                                                                    data-bs-toggle="pill" data-bs-target="#pills-groups"
                                                                    type="button" role="tab" aria-controls="pills-groups"
                                                                    aria-selected="false"><i className="icofont-puzzle"></i>
                                                                    Created</button>
                                                            </li>
                                                            <li className="nav-item" role="presentation">
                                                                <button className="nav-link" id="pills-friends-tab"
                                                                    data-bs-toggle="pill" data-bs-target="#pills-friends"
                                                                    type="button" role="tab" aria-controls="pills-friends"
                                                                    aria-selected="false"><i className="icofont-library"></i>
                                                                    Collection</button>
                                                            </li>

                                                            <li className="custom-select">
                                                                <select>
                                                                    <option value="1">Everything</option>
                                                                    <option value="2">Recent</option>
                                                                    <option value="3">Relevant</option>
                                                                    <option value="4">Popular</option>
                                                                </select>
                                                            </li>
                                                        </ul>
                                                        <div className="tab-content activity-content" id="pills-tabContent">

                                                            <div className="tab-pane fade mentions-section show active onsale-loadmore" id="pills-mentions" role="tabpanel" aria-labelledby="pills-mentions-tab">
                                                                <div className="row justify-content-center g-4">
                                                                    {data && <ProfileOnSale data={auctions} />}
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-favorites" role="tabpanel" aria-labelledby="pills-favorites-tab">
                                                                <div className="row justify-content-center g-4">
                                                                    {data && <ProfileOwned data={owned} />}
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab">
                                                                <div className="row justify-content-center g-4">
                                                                    {data && <ProfileCreated data={data} />}
                                                                </div>
                                                            </div>

                                                            <div className="tab-pane fade" id="pills-friends" role="tabpanel" aria-labelledby="pills-friends-tab">
                                                                <div className="row g-4">
                                                                    <div className="profile-widget search-widget">
                                                                        <div className="widget-inner">
                                                                            <div className="widget-title">
                                                                                <h5>Create New Collection</h5>
                                                                            </div>
                                                                            <div className="widget-content">
                                                                                <form action="" className="create-nft-form">
                                                                                    <p>Enter the NFT collection Info</p>
                                                                                    <div className="form-floating nft-search-input">
                                                                                        <input type="text" className="form-control" placeholder="Collection Name" spellCheck="false" data-ms-editor="true" value={collValue} onChange={(e)=>setCollValue(e.target.value)} />
                                                                                        <label>Collection Name</label>
                                                                                    </div>
                                                                                    <div className="form-floating nft-search-input">
                                                                                        <input type="text" className="form-control" placeholder="Discription" spellCheck="false" data-ms-editor="true" value={collDIs} onChange={(e)=>setCollDIs(e.target.value)} />
                                                                                        <label>Discription</label>
                                                                                    </div>
                                                                                    <div className="submit-btn-field text-center">
                                                                                        <div className="form-group">
                                                                                            <Link to="#">Create Collection</Link></div></div>
                                                                                </form>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {isLoading ? <CollectionSkeleton/> :
                                                                    <ProfileCollection data={collection} />}
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </article>
                                            </div>


                                            <div className="col-xl-3">
                                                <aside className="mt-5 mt-xl-0">
                                                    <div className="profile-widget search-widget">
                                                        <div className="widget-inner">
                                                            <div className="widget-title">
                                                                <h5>Search NFT</h5>
                                                            </div>
                                                            <div className="widget-content">
                                                                <p>Search from best Rarest NFT collections</p>
                                                                <div className="form-floating nft-search-input">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Search NFT" />
                                                                    <label>Search NFT</label>
                                                                    <button type="button"> <i
                                                                        className="icofont-search-1"></i></button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="widget widget-instagram">
                                                        <div className="widget-header">
                                                            <h5 className="title">Featured NFT</h5>
                                                        </div>
                                                        <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/02.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/06.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/08.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                        </ul>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="about" role="tabpanel" aria-labelledby="nav-about-tab">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-9">
                                                <article>

                                                    <div className="info-card mb-3">
                                                        <div className="info-card-title">
                                                            <h4>About</h4>
                                                        </div>
                                                        <div className="info-card-content">
                                                            <p>Collaboratively innovate compelling mindshare after
                                                                prospective partnerships Competently sereiz long-term
                                                                high-impact internal or "organic" sources via user friendly
                                                                strategic themesr areas creat Dramatically coordinate
                                                                premium partnerships rather than standards compliant
                                                                technologies ernd Dramatically matrix ethical collaboration
                                                                and idea-sharing through opensource methodologies and
                                                                Intrinsicly grow collaborative platforms vis-a-vis effective
                                                                scenarios. Energistically strategize cost effective ideas
                                                                before the worke unde.</p>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>


                                            <div className="col-xl-3">
                                                <aside className="mt-5 mt-xl-0">
                                                    <div className="profile-widget search-widget">
                                                        <div className="widget-inner">
                                                            <div className="widget-title">
                                                                <h5>Search NFT</h5>
                                                            </div>
                                                            <div className="widget-content">
                                                                <p>Search from best Rarest NFT collections</p>
                                                                <div className="form-floating nft-search-input">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Search NFT" />
                                                                    <label>Search NFT</label>
                                                                    <button type="button"> <i
                                                                        className="icofont-search-1"></i></button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="widget widget-instagram">
                                                        <div className="widget-header">
                                                            <h5 className="title">Featured NFT</h5>
                                                        </div>
                                                        <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/02.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/06.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/08.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                        </ul>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="nav-activity-tab">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-9">
                                                <article>
                                                    <h4 className="h4-title">Author's Activity</h4>
                                                    <div className="row gy-3">
                                                        <div className="col-12">
                                                            <div className="activity-item">
                                                                <div
                                                                    className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                                                    <div className="lab-thumb me-3 me-md-4">
                                                                        <img src={require(`../images/activity/01.gif`)} alt="img" />
                                                                    </div>
                                                                    <div className="lab-content">
                                                                        <h4><Link to="nft-single.html">Gold digger x</Link>
                                                                        </h4>
                                                                        <p className="mb-2">GOLD DIGGER (x Antoni Tudisco)
                                                                            #44/44 was put up for sale for
                                                                            <b>0.0991
                                                                                ETH</b>
                                                                        </p>
                                                                        <p className="user-id">By: <Link
                                                                            to="author.html">@rasselmrh</Link></p>
                                                                        <p>At: 10/07/2022, 10:03 am</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="activity-item">
                                                                <div
                                                                    className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                                                    <div className="lab-thumb me-3 me-md-4">
                                                                        <img src={require(`../images/activity/02.gif`)} alt="img" />
                                                                    </div>
                                                                    <div className="lab-content">
                                                                        <h4><Link to="nft-single.html">ghost lix xrf</Link>
                                                                        </h4>
                                                                        <p className="mb-2">two rare collection purchased for
                                                                            <b>0.001
                                                                                ETH</b>
                                                                        </p>
                                                                        <p className="user-id">By: <Link
                                                                            to="author.html">@reo2lxsr</Link></p>
                                                                        <p>At: 10/07/2022, 08:23 am</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="activity-item">
                                                                <div
                                                                    className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                                                    <div className="lab-thumb me-3 me-md-4">
                                                                        <img src={require(`../images/activity/04.gif`)} alt="img" />
                                                                    </div>
                                                                    <div className="lab-content">
                                                                        <h4><Link to="nft-single.html">Trust In meh </Link>
                                                                        </h4>
                                                                        <p className="mb-2">The Shopping Cart #54/65 was put up
                                                                            for sale for <b>0.021
                                                                                ETH</b>
                                                                        </p>
                                                                        <p className="user-id">By: <Link
                                                                            to="author.html">@reo2lxsr</Link></p>
                                                                        <p>At: 10/07/2022, 12:03 am</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="activity-item">
                                                                <div
                                                                    className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                                                    <div className="lab-thumb me-3 me-md-4">
                                                                        <img src={require(`../images/activity/05.gif`)} alt="img" />
                                                                    </div>
                                                                    <div className="lab-content">
                                                                        <h4><Link to="nft-single.html">Sysytan #0le </Link>
                                                                        </h4>
                                                                        <p className="mb-2">A offer of $200.00 was placed for
                                                                            ÜNDERSTANDING (Sean Williams) #1/20
                                                                        </p>
                                                                        <p className="user-id">By: <Link
                                                                            to="author.html">@reo2lxsr</Link></p>
                                                                        <p>At: 10/07/2022, 10:03 am</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="activity-item">
                                                                <div
                                                                    className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                                                    <div className="lab-thumb me-3 me-md-4">
                                                                        <img src={require(`../images/activity/03.gif`)} alt="img" />
                                                                    </div>
                                                                    <div className="lab-content">
                                                                        <h4><Link to="nft-single.html">ghost lix xrf</Link>
                                                                        </h4>
                                                                        <p className="mb-2">two rare collection purchased for
                                                                            <b>0.001
                                                                                ETH</b>
                                                                        </p>
                                                                        <p className="user-id">By: <Link
                                                                            to="author.html">@reo2lxsr</Link></p>
                                                                        <p>At: 10/07/2022, 02:03 pm</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>


                                            <div className="col-xl-3">
                                                <aside className="mt-5 mt-xl-0">
                                                    <div className="profile-widget search-widget">
                                                        <div className="widget-inner">
                                                            <div className="widget-title">
                                                                <h5>Search NFT</h5>
                                                            </div>
                                                            <div className="widget-content">
                                                                <p>Search from best Rarest NFT collections</p>
                                                                <div className="form-floating nft-search-input">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Search NFT" />
                                                                    <label>Search NFT</label>
                                                                    <button type="button"> <i
                                                                        className="icofont-search-1"></i></button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="widget widget-instagram">
                                                        <div className="widget-header">
                                                            <h5 className="title">Featured NFT</h5>
                                                        </div>
                                                        <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/02.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/06.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/08.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                        </ul>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="follower" role="tabpanel" aria-labelledby="nav-follower-tab">
                                    <div>
                                        <div className="row">
                                            <div className="col-xl-9">
                                                <div className="follow-wrapper">
                                                    <h4 className="h4-title">All Followers</h4>
                                                    <div className="row g-3">
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html" className=""><img
                                                                                    src={require(`../images/seller/collector-2.gif`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">@Ndrea _uido</Link>
                                                                                </h6>
                                                                                <p>3,002.98 ETH</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+38.78%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-1.png`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">goxio dom</Link>
                                                                                </h6>
                                                                                <p>$12,002.48</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-orange">-2.78%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-2.png`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">pter qido</Link>
                                                                                </h6>
                                                                                <p>$3,002.98</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+18.8%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-8.png`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">drexa_3xo</Link>
                                                                                </h6>
                                                                                <p>$23,002.98</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-orange">
                                                                        -23.81%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-6.png`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">rox zipper</Link>
                                                                                </h6>
                                                                                <p>20,02.98 ETH</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+48.38%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-3.gif`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="#">@Anra-_uido</Link> </h6>
                                                                                <p>$23,002.98</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-orange">-8.78%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-4.gif`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">rassel_mrh </Link>
                                                                                </h6>
                                                                                <p>93,002.98 ETH</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+88.78%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-7.png`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="author.html">holder don</Link>
                                                                                </h6>
                                                                                <p>$23,002.98</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+3.7%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="seller-item">
                                                                <div className="seller-inner">
                                                                    <div className="seller-part">

                                                                        <div className="assets-owner">
                                                                            <div className="owner-thumb veryfied">
                                                                                <Link to="author.html"><img
                                                                                    src={require(`../images/seller/collector-1.gif`)}
                                                                                    alt="seller-img" /></Link>
                                                                            </div>
                                                                            <div className="owner-content">
                                                                                <h6><Link to="#">rub_3l</Link> </h6>
                                                                                <p>$3,002.98</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="badge rounded-pill bg-blue">+31.78%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="load-btn">
                                                        <Link to="#" className="default-btn move-bottom"><span>Load
                                                            More</span> </Link>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-xl-3">
                                                <aside className="mt-5 mt-xl-0">
                                                    <div className="profile-widget search-widget">
                                                        <div className="widget-inner">
                                                            <div className="widget-title">
                                                                <h5>Search NFT</h5>
                                                            </div>
                                                            <div className="widget-content">
                                                                <p>Search from best Rarest NFT collections</p>
                                                                <div className="form-floating nft-search-input">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Search NFT" />
                                                                    <label>Search NFT</label>
                                                                    <button type="button"> <i
                                                                        className="icofont-search-1"></i></button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="widget widget-instagram">
                                                        <div className="widget-header">
                                                            <h5 className="title">Featured NFT</h5>
                                                        </div>
                                                        <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/02.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/03.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/06.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/04.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/08.jpg`)}
                                                                alt="nft-img" /></Link></li>
                                                            <li><Link to="item-details.html"><img loading="lazy"
                                                                src={require(`../images/nft-item/05.gif`)}
                                                                alt="nft-img" /></Link></li>
                                                        </ul>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="following" role="tabpanel"
                                    aria-labelledby="nav-following-tab">
                                    <div className="row">
                                        <div className="col-xl-9">
                                            <div className="follow-wrapper">
                                                <h4 className="h4-title">Following</h4>
                                                <div className="row g-3">
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html" className=""><img
                                                                                src={require(`../images/seller/collector-2.gif`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">@Ndrea _uido</Link> </h6>
                                                                            <p>3,002.98 ETH</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+38.78%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-1.png`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">goxio dom</Link> </h6>
                                                                            <p>$12,002.48</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-orange">-2.78%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-2.png`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">pter qido</Link> </h6>
                                                                            <p>$3,002.98</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+18.8%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-8.png`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">drexa_3xo</Link> </h6>
                                                                            <p>$23,002.98</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-orange"> -23.81%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-6.png`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">rox zipper</Link> </h6>
                                                                            <p>20,02.98 ETH</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+48.38%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-3.gif`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="#">@Anra-_uido</Link> </h6>
                                                                            <p>$23,002.98</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-orange">-8.78%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-4.gif`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">rassel_mrh </Link> </h6>
                                                                            <p>93,002.98 ETH</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+88.78%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-7.png`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="author.html">holder don</Link> </h6>
                                                                            <p>$23,002.98</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+3.7%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="seller-item">
                                                            <div className="seller-inner">
                                                                <div className="seller-part">

                                                                    <div className="assets-owner">
                                                                        <div className="owner-thumb veryfied">
                                                                            <Link to="author.html"><img
                                                                                src={require(`../images/seller/collector-1.gif`)}
                                                                                alt="seller-img" /></Link>
                                                                        </div>
                                                                        <div className="owner-content">
                                                                            <h6><Link to="#">rub_3l</Link> </h6>
                                                                            <p>$3,002.98</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className="badge rounded-pill bg-blue">+31.78%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="load-btn">
                                                    <Link to="#" className="default-btn move-bottom"><span>Load
                                                        More</span> </Link>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-xl-3">
                                            <aside className="mt-5 mt-xl-0">
                                                <div className="profile-widget search-widget">
                                                    <div className="widget-inner">
                                                        <div className="widget-title">
                                                            <h5>Search NFT</h5>
                                                        </div>
                                                        <div className="widget-content">
                                                            <p>Search from best Rarest NFT collections</p>
                                                            <div className="form-floating nft-search-input">
                                                                <input type="text" className="form-control"
                                                                    placeholder="Search NFT" />
                                                                <label>Search NFT</label>
                                                                <button type="button"> <i
                                                                    className="icofont-search-1"></i></button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="widget widget-instagram">
                                                    <div className="widget-header">
                                                        <h5 className="title">Featured NFT</h5>
                                                    </div>
                                                    <ul className="widget-wrapper d-flex flex-wrap justify-content-center">
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/03.gif`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/02.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/03.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/04.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/05.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/06.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/04.gif`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/08.jpg`)} alt="nft-img" /></Link>
                                                        </li>
                                                        <li><Link to="item-details.html"><img loading="lazy"
                                                            src={require(`../images/nft-item/05.gif`)} alt="nft-img" /></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default UserAccount;