import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IndexCatSkeleton from "../skeleton/indexCategory";

const HomeCategory = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading]= useState(true);

    useEffect(() => {
        axios.post("https://api.artsfields.com/admin/allCategories",{}).then(res => {
           // console.log(res.data.info)
            setData(res.data.info)
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err.response)
        })
    }, [setData]);

    const encryperate = (image) => (`data:image/png;base64,${image}`)
        
    return (<>
        <section className="catergory-section padding-top padding-bottom">
            <div className="container">
                <div className="section-header">
                    <h3 className="header-title">Browse By Catergory</h3>
                    <div className="header-content"><Link to="/explore"
                        className="default-btn style-2 small-btn move-right"><span>View All
                            <i className="icofont-circled-right"></i></span></Link> </div>
                </div>
                <div className="category-wrapper">
                    <div className="row row-cols-2 row-cols-md-4 row-cols-xl-auto g-3"> 

                        {isLoading ? <><IndexCatSkeleton/></> :
                        data && data.map((item) => {
                            return (<div className="col" key={item.id}>
                                <div className="cat-item">
                                    <div className="cat-inner">
                                        <Link to={`/category/${item.id}`}>
                                            <div className="cat-thumb">
                                                <img src={encryperate(item.symbol)} alt="Category_Image" />
                                            </div>
                                            <div className="cat-content">
                                                <h6>{item.name}</h6>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>)
                            }
                        )}
                       
                    </div>
                </div>
            </div>
        </section></>);
}

export default HomeCategory;