import { Text } from "./styles";

const IndexCatSkeleton = () => {
    return (<>
        <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div>
        <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div>
        <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div>
        <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div>
        <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div> <div className="col" >
            <div className="cat-item">
                <div className="cat-inner">
                    <div className="cat-thumb">
                        <Text height="100" width="150" radius="10"></Text>
                    </div>
                    <div className="cat-content">
                        <Text height="30" width="75" radius="10"></Text>
                    </div>
                </div>
            </div>
        </div>
        
    </>);
}

export default IndexCatSkeleton;