const SiteActivity = () => {
    return ( <>
    <section className="activity-section padding-top padding-bottom">
    <div className="container">

        <div className="section-wrapper">
            <div className="row">
                <div className="col-xl-8">
                    <div className="section-header">
                        <h3>Site Activites</h3>
                        <div className="nft-filter d-flex flex-wrap justify-content-center gap-15">
                            <div className="form-floating">
                                <select className="form-select" id="sortSelect"
                                    aria-label="Floating label select example">
                                    <option defaultValue>Newest</option>
                                    <option value="1">Trending</option>
                                    <option value="2">Most Viewed</option>
                                    <option value="3">Less Viewed</option>
                                    <option value="3">Ending Soon</option>
                                    <option value="3">Recently Sold </option>
                                    <option value="3">Recently Created </option>
                                    <option value="3">Recently Viewed </option>
                                    <option value="3">Ending Soon</option>
                                </select>
                                <label htmlFor="sortSelect">Sort By</label>
                            </div>
                        </div>
                    </div>
                    <div className="activity-wrapper activity-loadmore">
                        <div className="row gy-3">
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/01.gif`)}alt="img" />
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Gold digger x</a>
                                            </h4>
                                            <p className="mb-2">GOLD DIGGER (x Antoni Tudisco)
                                                #44/44 was put up for sale for
                                                <b>0.0991
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@rasselmrh</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/02.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 08:23 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/04.jpg`)}  alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Trust In meh </a>
                                            </h4>
                                            <p className="mb-2">The Shopping Cart #54/65 was put up
                                                for sale for <b>0.021
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 12:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/05.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Sysytan #0le </a>
                                            </h4>
                                            <p className="mb-2">A offer of $200.00 was placed for
                                                ÜNDERSTANDING (Sean Williams) #1/20
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/03.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 02:03 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/01.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Gold digger x</a>
                                            </h4>
                                            <p className="mb-2">GOLD DIGGER (x Antoni Tudisco)
                                                #44/44 was put up for sale for
                                                <b>0.0991
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@rasselmrh</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/02.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 08:23 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/04.jpg`)}  alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Trust In meh </a>
                                            </h4>
                                            <p className="mb-2">The Shopping Cart #54/65 was put up
                                                for sale for <b>0.021
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 12:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/05.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Sysytan #0le </a>
                                            </h4>
                                            <p className="mb-2">A offer of $200.00 was placed for
                                                ÜNDERSTANDING (Sean Williams) #1/20
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/03.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 02:03 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/05.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Sysytan #0le </a>
                                            </h4>
                                            <p className="mb-2">A offer of $200.00 was placed for
                                                ÜNDERSTANDING (Sean Williams) #1/20
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/03.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 02:03 pm</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/01.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">Gold digger x</a>
                                            </h4>
                                            <p className="mb-2">GOLD DIGGER (x Antoni Tudisco)
                                                #44/44 was put up for sale for
                                                <b>0.0991
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@rasselmrh</a></p>
                                            <p>At: 10/07/2022, 10:03 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="activity-item">
                                    <div className="lab-inner d-flex flex-wrap align-items-center p-3 p-md-4">
                                        <div className="lab-thumb me-3 me-md-4">
                                            <img src={require(`../images/activity/02.gif`)} alt="img"/>
                                        </div>
                                        <div className="lab-content">
                                            <h4><a href="nft-single.html">ghost lix xrf</a>
                                            </h4>
                                            <p className="mb-2">two rare collection purchased for
                                                <b>0.001
                                                    ETH</b>
                                            </p>
                                            <p className="user-id">By: <a href="author.html">@reo2lxsr</a></p>
                                            <p>At: 10/07/2022, 08:23 am</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <aside className="mt-5 mt-xl-0">
                        <div className="widget widget-tags">
                            <div className="widget-header">
                                <h5 className="title">Filters By</h5>
                            </div>

                            <ul className="widget-wrapper justify-content-start">
                                <li><a href="#"><i className="icofont-listine-dots"></i> Listing</a></li>
                                <li><a href="#" className="active"><i className="icofont-cart"></i> Purchases</a></li>
                                <li><a href="#"><i className="icofont-sale-discount"></i> Sales</a></li>
                                <li><a href="#"><i className="icofont-retweet"></i> Transfers</a></li>
                                <li><a href="#"><i className="icofont-fire-burn"></i> Burns</a></li>
                                <li><a href="#"><i className="icofont-court-hammer"></i> Bids</a></li>
                                <li><a href="#"><i className="icofont-like"></i> Likes</a></li>
                                <li><a href="#"><i className="icofont-favourite"></i> Following</a></li>
                                <li><a href="#"><i className="icofont-history"></i> Recent</a></li>
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>

        </div>
    </div>
</section>
    </> );
}
 
export default SiteActivity;