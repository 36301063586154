import styled from "styled-components";

export const Dropdown= styled.div`
position: relative;
`
export const DropdownToggle = styled.div`
    white-space: nowrap;

[role=button] {
    cursor: pointer;
}`

export const Header = styled.header`
border-bottom: var(--border);
height: 70px;
background-color: var(--header-background);
display:block;
@media (max-width: 768px)
{
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--header-background);
    z-index: 99;
    animation: fadeInUp 0.2s;
    
}
@media (min-width: 1200px)
{
    height: 100px;
}
.fixedTop{
    position: fixed;
    top: 0;
    width: 100%;
    background:  var(--header-background);
    z-index: 99;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
`
export const HeaderInner = styled.div`
`
export const ContainerFluid = styled.div`
width: 100%;
padding-right: var(--bs-gutter-x,.75rem);
padding-left: var(--bs-gutter-x,.75rem);
margin-right: auto;
margin-left: auto;
}`

export const HeaderContent = styled.div`
    display: flex;
    height: 70px;
    align-items: center;
    position: relative;
    width: 100%;
    justify-content: space-between;
    @media (min-width: 768px)
{
    padding-inline: 5%;
}
@media (min-width: 1200px)
 {
    height: 100px;
}
.menuActive{
    span:nth-of-type(1) {
        transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0, 0);
        top: 8px;
        width: 100%;
        margin: 0;
    }
    span:nth-of-type(2) {
        opacity: 0;
    }
    span:nth-of-type(3) {
        transform: rotate3d(0, 0, 1, -45deg);
        top: 8px;
        margin: 0;
    } 
    }
   .menuListActive{
    transform: translate3d(0, 0, 0);
   }
   .searchActive{
    top: 0;
   }
`
export const HeaderLogo = styled.div`
display: inline-flex;
border-right:var(--border);
padding-right: 1rem;
margin-right: 1rem;
align-items: center;
height: 100%;
justify-content: flex-start;
a{
    width:45px
}

@media (min-width: 768px)
{
    padding-right: 1.5rem;
    margin-right: 1.5rem;
    a{
        width: 140px;
    }
}
@media (min-width: 1200px)
{
    width: auto;
}

`

export const HeaderSearch= styled.div`
left: 0;
top: -71px;
height: 70px;
background-color: #f4f6f9;
z-index: 1;
padding: 0 15px;
transition: top 0.5s ease;
border-radius: 5px;
justify-content: flex-start;
display: flex;
flex-wrap: wrap;
align-items: center;
width: 100%;
position: absolute;
content: "";
input{
    width: calc(100% - 30px);
    height: 50px;
    background-color: transparent;
    color: #6f7d95;
    font-size: 14px;
    border-radius: 99px;
    border: none;
    padding: 0 45px 0 20px;
    font-weight: 400;
    border:var(--border);
    outline: none;
}
input:focus {
    box-shadow: 0 0 10px rgb(83 4 119 / 20%);
    border-color: rgba(130, 44, 255, 0.4);
}
button{
    right: 60px;
    top: 15px;
    height: 40px;
    width: auto;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    content: "";
    display: flex;
    flex-wrap: wrap;
    i {
        color: #6f7d95;
        transition: all 0.3s ease;
    }
    svg{
        width: 20px;
    height: auto;
    fill: #bdbdbd;
    transition: 0.5s ease;
    }
    
}

button.close {
        right: 15px;
    }
@media (min-width: 576px)
{
    padding: 0 30px;
    width: 100%;
    position: absolute;
    content: "";
    button {
        right: 75px;
    }
    button.close {
        right: 30px;
    }
}
@media (min-width: 1200px)
 {
    position: relative;
    top: auto;
    left: auto;
    width: 30%;
    padding: 0;
    border: none;
    background-color: transparent;
    input {
        padding: 0 60px 0 20px;
        width: 100%;
    }
    button {
        right: 20px;
    }
    button.close {
        display: none;
    }
}

`

export const Input = styled.input`
overflow: visible;
font-family: inherit;
font-size: 100%;
line-height: 1.15;
margin: 0;
`
export const Button = styled.button`
:not(:disabled) {
    cursor: pointer;
}
-webkit-appearance: button;
text-transform: none;    
border-radius: 0;
font-family: inherit;
font-size: 100%;
line-height: 1.15;
margin: 0;
overflow: visible;
`
export const HeaderMenu= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    background-color: var(--background-color-lite);
    z-index: 99;
    width: 280px;
    padding: 25px 25px 0;
    transform: translate3d(281px, 0, 0);
    transition: transform 0.5s ease;
    border-left:var(--border);
    margin-left: auto!important;
  
  @media (max-width: 1199px) {
    
      overflow-y: auto;

  }
  
  @media (min-width: 1200px)
 {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: 0;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    background-color: transparent;
    transform: translate3d(0, 0, 0);
    border: none;
    transition: transform 0s ease;
}

  :active {
    transform: translate3d(0, 0, 0);
  }
  ul{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
}
  `
  export const HeaderNav = styled.ul`

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;  
    width: 100%;
    position: relative;
    margin-bottom: 0!important;

    @media (min-width: 1200px)
 {
    flex-direction: row;
    align-items: center;
    width: auto;
}

`
export const HeaderNavItem=styled.li`
margin-bottom: 20px;
position: relative;
color: var(--font-color);

@media (min-width: 1200px)
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    margin-bottom: 0;
    margin-left: 30px;
}

ul{
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate3d(0px, 56px, 0px);
    padding: 15px;
}
a{
    font-size: 18px;
    font-family: "Nunito", sans-serif;
    height: 22px;
    font-weight: 700;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    text-decoration: none;
    color: var(--font-color);
    transition: all 0.3s ease;
    display: inline-block;
    text-decoration: none;
    img{
        width: 24px;
    }
}
a:hover{
    color: #822cff;
}

`
export const HeaderAction = styled.div`

@media (min-width: 768px)
 {
    margin-left: 5%; 
    .wallet {
        padding-left: 1.5rem;
    }
}
@media (min-width: 1200px)
 {
    margin-right: 0;
    .search {
        display: none;
    }  
    .profile{    
        padding-inline: 1.5rem;
    }
   
}
height: 100%;
justify-content: flex-end;
align-items: center;
margin-right: 20px;
    display: inline-flex;
    width: auto;
    .profile{
        width: auto;
        border-left:var(--border);
        border-right:var(--border);
        padding-inline: 1rem;
        ${DropdownToggle}{
            span:nth-of-type(1) {
                width: 24px;
                height: 24px;
                background: linear-gradient(90deg, #a200ff 0%, #ff7c6e 100%);
                border-radius: 50%;
                margin-right: 5px;
                text-align: center;
                display: inline-block;
                i {
                    color: #f4f6f9;
                    font-size: 14px;
                }
                
                
            }
        }
    }
    .wallet {
        padding-left: 1rem;
    }
`
export const HeaderActionsInner = styled.div`
:first-child {
    margin-left: 0;
}

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 22px;
    position: relative;
    margin-left: 20px;
    
    button{
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    }
`
export const MenuTrigger= styled.button`
position: relative;
width: 25px;
height: 20px;
background: none;
border: none;
appearance: none;
cursor: pointer;
margin-right: 1%;

span {
display: inline-block;
transition: all 0.4s;
box-sizing: border-box;
position: absolute;
left: 0;
width: 100%;
height: 2px;
background-color: var(--link-color);
border-radius: 4px;


}
span:nth-of-type(1) {
top: 0;
width:60%;
margin-left: 10px;
}
span:nth-of-type(2) {
top: 9px;
width:40%;
margin-left: 14px;
}
span:nth-of-type(3) {
bottom: 0;
width:100%;
margin-left: 0;
}

:hover{
span:nth-of-type(1) {
    margin-left: 0;
}
span:nth-of-type(2) {
    margin-left: 0;
}
span:nth-of-type(3) {
    margin-left: 0;
} 
}

@media (min-width: 992px)
{
display: none;
}
`

export const menuFixed_top = (fixed_top)=>{
        if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250){
            fixed_top.classList.add('fixedTop')
            fixed_top.classList.add("fadeInUp")
        }
        else {
            fixed_top.classList.remove('fixedTop');
            fixed_top.classList.remove("fadeInUp")
        }
    
}

export const menuToggle = (toggle_menu_btn, toggle_menu_list)=>{
    toggle_menu_btn.classList.toggle('menuActive')
    toggle_menu_list.classList.toggle('menuListActive')
}

export const searchToggle = (menu_search_open) =>{
    menu_search_open.classList.toggle('searchActive')
    
}

