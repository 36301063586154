import axios from 'axios';
import { useEffect, useState } from 'react';

export const PairExchange = ({currency, coin, price}) => {
const [coinPrice, setCoinPrice] =useState()
    useEffect(() => {
 axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${coin}&tsyms=${(currency).toUpperCase()}`)
                    .then(response => {
                        return response.data
                    })
                    .then(data => {
                        var item = JSON.stringify(data)
                        let strip = item.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                        const result = Number(strip).toFixed(2)
                        const prices = price * result
                        setCoinPrice(prices)
                    })
    },[currency, coin, price])
    return (<>{coinPrice && (coinPrice).toFixed(2)}</>);
}
