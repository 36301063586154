const HelpCenter = () => {
    return ( <>
    
<section className="help-search padding-top pb-5">
    <div className="container">
        <div className="help-search-wrapper text-center">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="help-search-content mb-4">
                        <h2>How Can We Help You?</h2>
                    </div>
                    <div className="search-bar input-group mb-3">
                        <input type="text" className="form-control" placeholder="Search our help center"
                            aria-label="Search our help center" aria-describedby="help-search"/>
                        <button className="btn btn-outline-secondary" type="button" id="help-search"><i
                                className="icofont-search-1"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div className="help-text padding-top padding-bottom">
    <div className=" container">
        <div className="row g-5 flex-lg-row-reverse">
            <div className="col-lg-9">
                <div className="help-text-wrapper">
                    <h3 className="mb-4">What is NFT?</h3>
                    <p>Before understanding NFT, you must understand what is fungibility. Fungibility is the
                        property of the
                        object or item to
                        be interchanged with another copy of the same type or similar type or different type without
                        affecting
                        its value. Money
                        is the best example of fungible tokens. If I have a $100 bill & you have five $20 bills then
                        we can
                        exchange it easily
                        without causing any loss or benefit to anyone. Similarly, Non-Fungible items are those which
                        can not
                        be
                        interchanged
                        like this.</p>
                    <p>Gallery art hanging in the museum is the best example of it. Every art is unique & can not be
                        replaced by
                        any similar
                        items.</p>
                    <p>Non-Fungible Token (NFT) represents a one-of-a-kind digital asset which has been securitised
                        by the
                        backing of
                        cryptography (the same technology we use to generate cryptocurrencies) and thus allows the
                        owner to
                        claim their
                        creation.
                        It's just a digital representation of your digital assets (image, video, text file, pdf,
                        etc.) whose
                        proof-of-existence
                        & proof-of-ownership is being managed by a Blockchain ledger, simply called Blockchain. This
                        is only
                        possible because
                        the Blockchain ledger is considered immutable (means can not be altered or removed) and thus
                        cannot
                        be
                        tampered with by
                        anyone without paying billions of dollars & years of time.</p>
                    <p>NFTs can be created for a wide variety of digital art and assets like music, painting, memes,
                        videos,
                        gaming
                        collectibles and a lot more. Since NFTs provide a sense of belonging and help in
                        establishing a
                        proof of ownership, they
                        are being used to secure Domains, gaming collectibles, any priceless memory, and much
                        more.</p>
                    <br/>
                    <h3 className="mb-4">What is Wallet & How to Connect?</h3>
                    <p>A cryptocurrency wallet is a device, physical medium, program or a service which stores the
                        public
                        and/or private keys
                        for cryptocurrency transactions. In addition to this basic function of storing the keys, a
                        cryptocurrency wallet more
                        often also offers the functionality of encrypting and/or signing information</p>
                    <img className="mb-4" src={require(`../images/help/01.jpg`)} alt="Connect Wallet Image" />
                    <p>MetaMask is amongst the most popular crypto wallets and browser extensions, and it acts as a
                        link to get connected to
                        numerous blockchain applications. The ease of use and high accessibility are the crucial
                        aspects that make MetaMask a
                        preferred choice among crypto enthusiasts.
                        MetaMask is a browser extension that enables one to store their tokens and also to get
                        linked with other blockchain
                        applications. It provides a simple user interface, backed by the security of keys and
                        passwords. Hence, it emerges as
                        one of the most reliable options for people to keep their crypto tokens safe.</p>
                </div>
            </div>
            <div className="col-lg-3">
                <aside className="related-help">
                    <h5>Related Links</h5>
                    <ul className="related-linklist">
                        <li><a href="#" className="related-link">How to link your wallet to an NFT Marketplace?</a></li>
                        <li><a href="#" className="related-link">How to buy an NFT from an NFT Marketplace?</a></li>
                        <li><a href="#" className="related-link">How To Create a Store In Anftiz</a></li>
                        <li><a href="#" className="related-link">Set Up Your Crypto Wallet</a></li>
                    </ul>
                </aside>
            </div>
        </div>

    </div>
</div>

<section className="process-section padding-top padding-bottom">
    <div className="container">
        <div className="section-header justify-content-center">
            <div className="header-title">
                <h3>Get Started Creating And Selling Your NFTs</h3>
            </div>
        </div>
        <div className="section-wrapper">
            <div className="nft-sell-wrapper">
                <div className="row justify-content-center g-4">
                    <div className="col-md-6">
                        <div className="nft-item style-3">
                            <div className="nft-inner text-center">
                                <div className="nft-thumb">
                                    <img src={require(`../images/nft-item/style-3/01.png`)} alt="nft-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h5>Set Up Your Wallet</h5>
                                        <p>Once you've set up your wallet of choice, connect
                                            it to NFTically by clicking the wallet icon in the top right corner.
                                            Learn about the wallets we support.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="nft-item style-3">
                            <div className="nft-inner text-center">
                                <div className="nft-thumb">
                                    <img src={require(`../images/nft-item/style-3/02.png`)} alt="nft-img" />
                                </div>
                                <div className="nft-content">
                                    <h5>Creat Your Collection</h5>
                                    <p>Click Create and set up your collection.
                                        Add social links, a description, profile & banner images,
                                        and set a secondary sales fee.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="nft-item style-3">
                            <div className="nft-inner text-center">
                                <div className="nft-thumb">
                                    <img src={require(`../images/nft-item/style-3/03.png`)} alt="nft-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h5>Add Your NFTs</h5>
                                        <p>Upload your work (image, video, audio, or 3D art),
                                            add a title and description, and customize your NFTs with
                                            properties, stats, and unlockable content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="nft-item style-3">
                            <div className="nft-inner text-center">
                                <div className="nft-thumb">
                                    <img src={require(`../images/nft-item/style-3/04.png`)} alt="nft-img"/>
                                </div>
                                <div className="nft-content">
                                    <div className="author-details">
                                        <h5>List Them For Sale</h5>
                                        <p>Choose between auctions, fixed-price listings,
                                            and declining-price listings. You choose how you want to sell
                                            your NFTs, and we help you sell them!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<div className="cant-find padding-bottom padding-top">
    <div className="container">
        <div className="cant-find-content text-center">
            <h3 className="mb-3">Not finding what you are looking for?</h3>
            <p>Chat with us or send us an email.</p>
            <ul className="mt-4 d-flex flex-wrap justify-content-center align-items-center">
                <li><a href="#"><span><i className="icofont-ui-messaging"></i></span> Chat With Us</a></li>
                <li><a href="#"><span><i className="icofont-envelope"></i></span> Email Us</a></li>
            </ul>
        </div>
    </div>
</div>



    </> );
}
 
export default HelpCenter;