import axios from "axios";
import { useState } from "react";
import { DangerAlert, SuccessAlert } from "../../style/generalStyle";
import { PairExchange } from "../components/coinExchange";
import { LoadingButton } from "../components/loading";

const PlaceBidModal = ({data, id}) => {

    const [regResponse, setRegResponse] = useState('')
  
    const closeModal = (() => {
        let modal = document.getElementById("myModal");
        modal.style.display = "none";
        modal.style.transition = "1s ease-out";
    })

    const [isBidLoading, setIsBidLoading] = useState(false)
    const [bidPrice, setBidPrice] = useState("")
    const placeBid = (e) => {
        e.preventDefault()
      
            let isnum = /^\d+\.\d+$/.test(bidPrice);
            let isdob = /^\d+$/.test(bidPrice);
            if (isnum || isdob) {
                setIsBidLoading(true)
                const nftId = id
                const userId = JSON.parse(localStorage.getItem('__LUD')).userId
                const payload = {
                    userId: userId,
                    nftId: nftId,
                    price: bidPrice,
                }
                axios.post("https://api.artsfields.com/user/placeBid", payload)
                    .then((res) => {
                        //console.log(res)
                        setRegResponse(<SuccessAlert>Bidding sent</SuccessAlert>)
                    })
                    .catch((err) => {
                        if (err.response.status === 420) {
                            setRegResponse(<DangerAlert>Sorry you've already placed your bid</DangerAlert>)
                        }

                        else {
                            setRegResponse(<DangerAlert>Sorry an error occured </DangerAlert>)
                        }

                    })
                    .finally(() => {
                        setInterval(() => {
                            setRegResponse('')
                            //  setBidPrice("")
                        }, 5000);

                        setIsBidLoading(false)
                    })
            }
            else {

                setRegResponse(<DangerAlert>Invalid Input</DangerAlert>)
                setInterval(() => {
                    setRegResponse('')
                    //  setBidPrice("")
                }, 5000);
            }
    }

    return(<>
      
               
    <div className="modal-header">
            <div className="modal-title">
                Place Bid
            </div>
            <span className="close" onClick={closeModal}>&times;</span>
        </div>
        <div className="modal-body">
            <p>Current Sales Price: {data.price} {(`${data.currency}`).toUpperCase()}
                ($<PairExchange currency="USD" coin={data.currency} price={data.price} />)</p>
            <form className="account-form" onSubmit={placeBid}>
                <div className="form-floating mb-3" id="enterBidding">
                    <input type="text" className="form-control" placeholder="Bid Amount" value={bidPrice} onChange={(e) => setBidPrice(e.target.value)} />
                    <label htmlFor="enterBidding">Enter Bid in ETH</label>

                </div>
                {regResponse}
                <div className="form-group">
                    {isBidLoading ? <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> :
                        <>  <button className="d-block default-btn move-top" type="submit"><span>Place Now</span></button></>}
                </div>
            </form>
        </div>
    </>)
}
export default PlaceBidModal;