//import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { CollectionList } from "../components/Swipers";

const ProfileCollection = ({ data }) => {

    // useLayoutEffect(() => {
    //     console.log(data)
    // }, [data])
    return (<>
       
        {data && data.map(item => (
            <div className="col-md-6" key={item.collectionId}>
                <div className="nft-item collection-item">
                    <div className="nft-inner">
                        <div className="coll-thumb-wrapper">
                            <CollectionList data={item.nfts} />
                        </div>
                        <div className="nft-content">
                            <div className="collection-title">
                                <h4><Link to={`/collections/${item.collectionId}`}>{item.collectionName}</Link> </h4>
                                <p>Collection has {item.nfts.length} items</p>
                            </div>

                            <div
                                className="author-details d-flex flex-wrap justify-content-between align-items-center">
                                <div className="single-author d-flex align-items-center">
                                    <Link to={`/author/${item.userId}`} className="veryfied"><img
                                        src={`https://images.artsfields.com/avatar/${item.userAvatar}`} alt="author-img" /></Link>
                                    <h6><Link to={`/author/${item.userId}`}>{item.userName}</Link></h6>
                                </div>
                                <span className="badge rounded-pill"><i
                                    className="icofont-heart"></i>
                                    11.3k</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))

        }


    </>);
}

export default ProfileCollection;