const TopCollectors = () => {
    return ( <>
    <section className="seller-section pb-100">
    <div className="container">
        <div className="section-header">
            <h3 className="header-title">Top Collections</h3>
            <div className="header-content">
                <ul className="filter-group d-flex flex-wrap align-items-center">
                    <li className="li collection-filter">
                        <div className="select-wrapper arrow-blue" data-icon="&#xea99;">
                            <select className="form-select " aria-label="Collection select">
                                <option defaultValue>Collections</option>
                                <option value="1">Newest</option>
                                <option value="2">Trending</option>
                                <option value="3">Most Popular</option>
                            </select>
                        </div>
                    </li>
                    <li className="li day-filter">
                        <div className="select-wrapper arrow-orange" data-icon="&#xea99;">
                            <select className="form-select" aria-label="Day select">
                                <option defaultValue>Last 7 Days</option>
                                <option value="1">Last 15 Day</option>
                                <option value="2">Last Month</option>
                                <option value="3">All Time</option>
                            </select>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className="section-wrapper">
            <div className="seller-wrapper">
                <div className="row g-3">
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">01</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html" className=""><img
                                                    src={require(`../../images/seller/collector-2.gif`)} alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">@Ndrea _uido</a> </h6>
                                            <p>3,002.98 ETH</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+38.78%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">02</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-1.png`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">goxio dom</a> </h6>
                                            <p>$12,002.48</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-orange">-2.78%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">03</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-2.png`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">pter qido</a> </h6>
                                            <p>$3,002.98</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+18.8%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">04</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-8.png`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">drexa_3xo</a> </h6>
                                            <p>$23,002.98</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-orange"> -23.81%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">05</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-6.png`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">rox zipper</a> </h6>
                                            <p>20,02.98 ETH</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+48.38%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">06</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-3.gif`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="#">@Anra-_uido</a> </h6>
                                            <p>$23,002.98</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-orange">-8.78%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">07</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-4.gif`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">rassel_mrh </a> </h6>
                                            <p>93,002.98 ETH</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+88.78%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">08</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-7.png`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="author.html">holder don</a> </h6>
                                            <p>$23,002.98</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+3.7%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6">
                        <div className="seller-item">
                            <div className="seller-inner">
                                <div className="seller-part">
                                    <p className="assets-number">09</p>
                                    <div className="assets-owner">
                                        <div className="owner-thumb veryfied">
                                            <a href="author.html"><img src={require(`../../images/seller/collector-1.gif`)}
                                                    alt="seller-img"/></a>
                                        </div>
                                        <div className="owner-content">
                                            <h6><a href="#">rub_3l</a> </h6>
                                            <p>$3,002.98</p>
                                        </div>
                                    </div>
                                </div>
                                <span className="badge rounded-pill bg-blue">+31.78%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-5">
                    <a href="rank.html" className="default-btn move-right"><span>Go To Rank</span></a>
                </div>
            </div>
        </div>
    </div>
</section>
    </> );
}
 
export default TopCollectors;