import { Link } from "react-router-dom";
import {
    Button, ContainerFluid, Header, HeaderAction,
    HeaderActionsInner, HeaderContent, HeaderInner, HeaderLogo, HeaderMenu, HeaderNav,
    HeaderNavItem, HeaderSearch, Input, menuFixed_top, menuToggle, MenuTrigger,
    searchToggle
} from "./style/headerStyle";
import logo_mobile from "./images/logo/artsfield_moble_logo.png"
import { useEffect, useState, useCallback } from "react";
import Cookies from 'js-cookie'
import axios from "axios";
const HeaderDiv = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loggedInHeader, setLoggedInHeader] = useState()
    const [isMobile, setIsMobile] = useState(true)

    useEffect(() => {
        const log = Cookies.get('__Logged')
        // console.log(log)
        if (log === "" || log === undefined) {
            setIsLoggedIn(false)
        }
        else {
            setIsLoggedIn(true)
        }

    }, [isLoggedIn])

    const updateBalance =useCallback((userId)=>{
        axios.post(`https://api.artsfields.com/user/userBalance`, { userId: userId })
        .then((res) => {
            return res.data.wallet
        })
        .then((data) => {
            setLoggedInHeader(<>
                <div className="wallet-btn">
                    <Link to="/wallet"><span><i className="icofont-wallet" data-blast="color"></i></span> <span
                        className="d-none d-md-inline">{Number(data.balance).toFixed(8) + data.coinShort}</span> </Link>
                </div></>)
        })
    },[])

    useEffect(() => {
        if (isLoggedIn) {
            const userId = JSON.parse(localStorage.getItem('__LUD')).userId
            updateBalance(userId)
            setInterval(() => {
                updateBalance(userId)
            }, 10000);
        }
        else { setLoggedInHeader() }

        const handleResize = () => {
            if (window.innerWidth > 767) {
                setIsMobile(false)
            }
            else if (window.innerWidth < 767) {
                setIsMobile(true)
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        // header change
        var fixed_top = document.querySelector(`${HeaderInner}`);
        window.onscroll = () => { menuFixed_top(fixed_top) }

        // menu handbugger
        var toggle_menu_btn = document.querySelector(`${MenuTrigger}`);
        var toggle_menu_list = document.querySelector(`${HeaderMenu}`);
        toggle_menu_btn.onclick = () => { menuToggle(toggle_menu_btn, toggle_menu_list) }

        // header search
        var menu_search = document.querySelector(`${HeaderSearch}`);
        var menu_search_open = document.querySelector(`${HeaderActionsInner}`);
        var menu_search_close = document.getElementById('closeSearch');
        menu_search_open.onclick = () => { searchToggle(menu_search) }
        menu_search_close.onclick = (e) => { searchToggle(menu_search) }



    }, [isLoggedIn, isMobile, updateBalance])


    let [theme, setTheme] = useState()


    const setDark = () => {
        localStorage.setItem("theme", "dark");
        document.documentElement.setAttribute("data-theme", "dark");

    };

    const setLight = () => {
        localStorage.setItem("theme", "light");
        document.documentElement.setAttribute("data-theme", "light");
        setTheme(<img src={require(`./images/icon/moon.png`)} className="dark" alt="toggle_theme" />)
    };

    const storedTheme = localStorage.getItem("theme");

    const prefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;

    const defaultDark =
        storedTheme === "dark" || (storedTheme === null && prefersDark);

    if (defaultDark) {
        setDark();

    }
    const [toggler, toggleTheme] = useState(defaultDark)

    if (toggler) {
        //console.log(toggler)
        theme = <img src={require(`./images/icon/sun.png`)} className="light" alt="toggle_theme" />
    } else {
        theme = <img src={require(`./images/icon/moon.png`)} className="dark" alt="toggle_theme" />
    }
    const switchTheme = () => {

        if (theme.props.className === 'light') {
            setLight();
            setTheme(<img src={require(`./images/icon/moon.png`)} className="dark" alt="toggle_theme" />)
            toggleTheme(false)
        }
        else if (theme.props.className === 'dark') {
            setDark();
            setTheme(<img src={require(`./images/icon/sun.png`)} className="light" alt="toggle_theme" />)
            toggleTheme(true)
        }
    }

    return (<>

        <Header>
            <HeaderInner>

                <ContainerFluid >
                    <HeaderContent>
                        <HeaderLogo >
                            <Link to="/">
                                {isMobile ? <img src={logo_mobile} alt="logo" /> : <>{
                                    theme.props.className === 'light' ? <img src={require(`./images/logo/artsfield_light.png`)} alt="logo" /> :
                                        <img src={require(`./images/logo/artsfield_dark.png`)} alt="logo" />

                                }</>
                                }
                            </Link>
                        </HeaderLogo>

                        <HeaderSearch >
                            <Input type="text" placeholder="Search items, collections, and creators" />
                            <Button><i className="icofont-search-2"></i></Button>
                            <Button className="close" id="closeSearch" >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path
                                        d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
                                </svg></Button>
                        </HeaderSearch>
                        <HeaderMenu >
                            <HeaderNav>
                                <HeaderNavItem >
                                    <Link to="/explore" role="button">Explore</Link>
                                </HeaderNavItem>
                                <HeaderNavItem>
                                    <Link to="/auction" role="button">Live Auction</Link>
                                </HeaderNavItem>
                                <HeaderNavItem>
                                    <Link className="header__nav-link" to="#" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">NFTs</Link>

                                    <ul className="dropdown-menu header__nav-menu">
                                        <li><Link className="drop-down-item" to="/create">Create</Link></li>
                                        <li><Link className="drop-down-item" to="/collections">Collections</Link></li>
                                        <li><Link className="drop-down-item" to="/rank">NFTs Rankings</Link></li>
                                    </ul>
                                </HeaderNavItem>

                                <HeaderNavItem>
                                    <Link className="header__nav-link" to="#" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Tools</Link>

                                    <ul className="dropdown-menu header__nav-menu" data-popper-placement="top-start">
                                        <li><Link className="drop-down-item" to="/activities">Site Activity</Link></li>
                                        <li><Link className="drop-down-item" to="/help">Help Center</Link></li>
                                        <li><Link className="drop-down-item" to="/policy">Privacy & Policy</Link></li>
                                        <li><Link className="drop-down-item" to="/terms">Terms & Condition</Link></li>

                                    </ul>
                                </HeaderNavItem>
                                <HeaderNavItem >
                                    <Link to="#" onClick={switchTheme} >
                                        {theme}

                                    </Link>
                                    <input type="checkbox" id="checkbox" onChange={(e) => toggleTheme(e.target.checked)} defaultChecked={toggler}
                                        style={{ display: 'none' }} />
                                </HeaderNavItem>

                                {isLoggedIn ? <> <HeaderNavItem className="header__nav-item">
                                    <Link className="header__nav-link" to="#" role="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10"><svg
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <path d="M12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10ZM5,10a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm14,0a2,2,0,1,0,2,2A2,2,0,0,0,19,10Z" />
                                        </svg></Link>

                                    <ul className="dropdown-menu header__nav-menu">
                                        <li><Link className="drop-down-item" to="/author">Profile</Link></li>
                                        <li><Link className="drop-down-item" to="/activity">My Activites</Link></li>
                                        <li><Link className="drop-down-item" to="signout">Sign Out</Link></li>
                                    </ul>
                                </HeaderNavItem></> :
                                    <HeaderNavItem className="header__nav-item">
                                        <Link className="header__nav-link" to="#" role="button" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10"><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path
                                                    d="M12,10a2,2,0,1,0,2,2A2,2,0,0,0,12,10ZM5,10a2,2,0,1,0,2,2A2,2,0,0,0,5,10Zm14,0a2,2,0,1,0,2,2A2,2,0,0,0,19,10Z" />
                                            </svg></Link>

                                        <ul className="dropdown-menu header__nav-menu">
                                            <li><Link className="drop-down-item" to="login">Sign In</Link></li>
                                            <li><Link className="drop-down-item" to="signup">Sign Up</Link></li>
                                            <li><Link className="drop-down-item" to="forgot-pass">Forgot Password</Link></li>
                                        </ul>
                                    </HeaderNavItem>
                                }

                            </HeaderNav>
                        </HeaderMenu>

                        <HeaderAction>
                            <HeaderActionsInner className="search header__action--search">
                                <Button className="header__action-btn" type="button"><i className="icofont-search-1"></i></Button>
                            </HeaderActionsInner>

                            {loggedInHeader}

                        </HeaderAction>

                        <MenuTrigger>
                            <span></span>
                            <span></span>
                            <span></span>
                        </MenuTrigger>
                    </HeaderContent>
                </ContainerFluid>
            </HeaderInner>
        </Header>


    </>);
}

export default HeaderDiv;