import axios from "axios";
import { useState, useEffect } from "react";
import LiveAuction from "./auctions/live";
import AuctionSkeleton from "./skeleton/auction";

const Auction = () => {
    const [liveAuction, setLiveAuction] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        axios.post("https://api.artsfields.com/getData/getLiveAuction", {})
            .then(res => {
                setLiveAuction(res.data.info)
                setIsLoading(false)
            })
    }, [])
    return (<>
        <section className="explore-section padding-top padding-bottom">
            <div className="container">
                <div className="section-header">
                    <div className="nft-filter d-flex flex-wrap align-items-center justify-content-center  gap-15">
                        <h3>Live Auctions</h3>
                    </div>
                    <div className="nft-search">
                        <div className="form-floating nft-search-input">
                            <input type="text" className="form-control" id="nftSearch" placeholder="Search NFT" />
                            <label htmlFor="nftSearch">Search NFT</label>
                            <button type="button"> <i className="icofont-search-1"></i></button>
                        </div>
                    </div>
                </div>
                <div className="section-wrapper">
                    <div className="explore-wrapper auction-loadmore">
                        <div className="row g-4">

                            {isLoading ? <AuctionSkeleton /> : <LiveAuction data={liveAuction} />}
                        </div>

                    </div>
                </div>
            </div>
        </section>
    </>);
}

export default Auction;