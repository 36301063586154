// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UserHeader = ({data}) => {

    // useEffect(() => {
    //    // console.log(data);
    //    const user = JSON.parse(localStorage.getItem('__LUD'));
    //   // const fullName = user.fullName 
    // }, [data]);
    


    return ( <>
     <div className="profile-item">
                        <div className="profile-cover">
                            <img src={require(`../../images/profile/cover.jpg`)} alt="cover-pic"/>
                            <div className="edit-photo custom-upload">
                                <div className="file-btn"><i className="icofont-camera"></i>
                                    Edit Photo</div>
                                <input type="file"/>
                            </div>
                        </div>
                        <div className="profile-information">
                            <div className="profile-pic">
                                <img src={`https://images.artsfields.com/avatar/${data.userAvatar}`} alt="DP"/>
                                {/* <div className="custom-upload">
                                    <div className="file-btn">
                                        <span className="d-none d-lg-inline-block"> <i className="icofont-camera"></i>
                                            Edit</span>
                                        <span className="d-lg-none mr-0"><i className="icofont-plus"></i></span>
                                    </div>
                                    <input type="file"/>
                                </div> */}
                            </div>
                            <div className="profile-name">
                            <h4>{data.userName}</h4>
                                <p>Joined October 2022</p>
                            </div>
                            <ul className="profile-contact">
                                <li className="crypto-copy">
                                    <div id="cryptoCode" className="crypto-page">
                                    <input id="cryptoLink" value={`${data.walletAddress}`} readOnly/>
                                        <div id="cryptoCopy" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Copy Address">
                                            <span className="copy-icon">
                                                <i className="icofont-ui-copy" aria-hidden="true"
                                                    data-copytarget="#cryptoLink"></i>
                                            </span>

                                        </div>
                                    </div>

                                </li>
                                <li>
                                    <Link to="#">
                                        <div className="icon"><i className="icofont-speech-comments"></i></div>
                                        <div className="text">
                                            <p>Messages</p>
                                        </div>
                                    </Link>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className="profile-item d-none">
                        <div className="lab-inner">
                            <div className="lab-thumb">
                                <Link to="#"><img src={require(`../../images/profile/1.gif`)} alt="profile"/></Link>
                            </div>
                            <div className="lab-content">
                                <div className="profile-name">
                                    <div className="p-name-content">
                                        <h4>William Smith</h4>
                                        <p>Active 02 Minutes Ago</p>
                                    </div>

                                    <div className="contact-button">
                                        <button className="contact-btn">
                                            <i className="icofont-info-circle"></i>
                                        </button>
                                    </div>
                                </div>
                                <ul className="profile-contact">
                                    <li>
                                        <Link to="#">
                                            <div className="icon"><i className="icofont-user"></i></div>
                                            <div className="text">
                                                <p>Add Friends</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="icon"><i className="icofont-envelope"></i></div>
                                            <div className="text">
                                                <p>Publice Message</p>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#">
                                            <div className="icon"><i className="icofont-envelope"></i></div>
                                            <div className="text">
                                                <p>Private Message</p>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div></> );
}
 
export default UserHeader;