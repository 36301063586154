import { useNavigate } from 'react-router-dom';

const Error404 = () => {
    const navigate = useNavigate();
    function handleClick() {
        navigate("/", { replace: true });
      }
    return ( <>
    <section className="error-section padding-top padding-bottom">
        <div className="container">
            <div className="section-wrapper">
                <div className="error-item">
                    <div className="error-thumb">
                        <img src={require('../../images/404/404.png')} alt="404"/>
                    </div>
                    <div className="error-content">
                        <h3>Oops! This Page Not Found</h3>
                        <p>We are Really Sorry But the Page you Requested is Missing <i className="icofont-worried"></i></p>
                        <div onClick={handleClick} className="default-btn move-left"><span>Go Back to Home</span> </div>
                    </div>
                </div>
            </div>
        </div>
    </section></> );
}
 
export default Error404;