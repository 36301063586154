import { Text } from "./styles";

const NFTdetailsSkeleton = () => {
    return (<>
        <div className="row g-5">
            <div className="col-lg-6">
                <div className="item-desc-part">
                    <div className="item-desc-inner">
                        <div className="item-desc-thumb">
                        <Text height="200"  radius="10"/>
                        </div>
                        <div className="item-desc-content">
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <Text height="25" width="500" radius="10" />
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="details-tab tab-pane fade show active" id="nav-details"
                                    role="tabpanel" aria-labelledby="nav-details-tab">

                                    <Text height="100" width="350" radius="10" mb="10"/>
                                    <div className="author-profile d-flex flex-wrap align-items-center gap-15">
                                        <div className="author-p-thumb">
                                        <Text height="50" width="50" radius="500"/>
                                        </div>
                                        <div className="author-p-info">
                                        <Text height="40" width="270" radius="10"/>
                                        </div>
                                    </div>
                                    <ul className="other-info-list">
                                        <li className="item-other-info">
                                            <Text height="20" width="300" radius="10" />
                                        </li>
                                        <li className="item-other-info">
                                            <Text height="20" width="300" radius="10" />
                                        </li>
                                        <li className="item-other-info">
                                            <Text height="20" width="300" radius="10" />
                                        </li>

                                        <li className="item-other-info">

                                            <Text height="20" width="300" radius="10" />

                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="item-buy-part">
                    <div className="nft-item-title">
                        <Text height="50" width="500" radius="10" />
                       
                    </div>
                    <div className="item-details-countdown">

                        <Text height="25" width="200" radius="10" />

                    </div>
                    <div className="item-price">

                        <Text height="25" width="200" radius="10" />
                    </div>
                    <div className="buying-btns d-flex flex-wrap">
                        <Text height="25" width="100" radius="10" />
                        <Text height="25" width="100" radius="10" />
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default NFTdetailsSkeleton;