import styled from "styled-components";


export const BannerSection = styled.section`
@media (max-width: 768px){
top: 70px;
}
background-image: var(--banner-image);
background-size: cover;
background-position: center;
overflow: hidden;
position: relative;
`

export const Container = styled.div`


    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
@media (min-width: 576px)
{
    max-width: 540px;
}
@media (min-width: 768px)
{
    max-width: 720px;
}
@media (min-width: 992px)
{
    max-width: 960px;
}
@media (min-width: 1200px)
{
    max-width: 1140px;
}
@media (min-width: 1400px)
{
    max-width: 1320px;
}
`
export const BannerWrapper =styled.div`
padding-block: 60px;

@media (min-width: 768px){
    padding-block: 80px;
}`

export const ExcatItem = styled.div`
`
export const ExcatItemContent = styled.div`
`

export const ExploreCategory =styled.div`
margin-bottom: 3rem!important;
${ExcatItem}{
    border-radius: 10px;
    background-color: var(--background-color-lite);
    cursor:pointer;
    ${ExcatItemContent} {
        flex-direction: column;
        padding: 20px;
        gap: 5px;
        align-items: center;    
        justify-content: center;
        height: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        i {
        font-size: calc(1.375rem + 1.5vw);
            }
            @media (min-width: 1200px){
            i {
                    font-size: 2.5rem;
                } 
    }
        
    }
   
}.active, ${ExcatItem}:hover {
    background-color: var(--card-background-color);
    color: var(--font-color);
    font-weight: 700;
}


`
export const FlexRow =styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
margin-top: calc(-1 * 0.5rem);
margin-right:calc(-.5 * 0.5rem);
margin-left:calc(-.5 * 0.5rem);
justify-content: space-around;
.show{
    display:flex !important;
}
`
 export const FlexColumn = styled.div`
 
 display:flex;
 flex-direction:column;`
export const ExploreList = styled.div`
display:none;
.nft-inner{
    width: 100%;
}
`
export const NFTImage = styled.div`
a{
    background-repeat: no-repeat;
background-position: center center;
background-size: cover;
cursor: pointer;
width: 100%;
display: inline-block;
height: 250px;
border-radius: 20px;
max-height: 300px;
}

` 
export const NFTCurrentImageMini = styled.img`
max-width: 100%;
height: auto;
width: 20px;
`

export const NFTImageThumbnail = styled.img `
cursor: pointer;
width: 100%;
display: inline-block;
height: 330px;
max-height: 350px;
border-radius: 20px;

@media (min-width: 768px) {
    height: 250px;
    max-height: 300px;
}
` 
export const FlexList =styled.li`
display: flex`
export const Mr5 = styled.div`
margin-right:5px`
export const Ml5 = styled.div`
margin-left:5px`