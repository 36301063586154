import './UI/css/global.css'
import './UI/css/icofont.min.css'
import './UI/css/animate.css'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import BodyMain from './UI/bodyMain';
import HeaderDiv from "./UI/header";
import FooterDiv from './UI/footer';
import Login from './UI/pages/login';
import Signup from './UI/pages/signup';
import Explore from './UI/pages/explore';
import Auction from './UI/pages/auctions';
import CategorySingle from './UI/pages/home/categotySingle';
import Error404 from './UI/pages/components/404';
import UserWallet from './UI/pages/wallet';
import UserAccount from './UI/pages/user';
import Signout from './UI/pages/signout';
import UserVerify from './UI/pages/auth/verifyUserReg';
import CreateNFT from './UI/pages/NFTs/createNFT';
import NftDetails from './UI/pages/NFTs/nftDetails';
import HelpCenter from './UI/pages/help';
import SiteActivity from './UI/pages/siteActivity';
import AuthorProfile from './UI/pages/author';
import NFTCollections from './UI/pages/collections';
import CollectionSingle from './UI/pages/collectionSingle';
import InternetConnection from './connection';

function App() {
  return (
  <>
  <Router> 
    <HeaderDiv />
    <InternetConnection/>
  <Routes>
  <Route index element={<BodyMain />} />
  <Route path='/login' element={<Login/>}/>
  <Route path='/signup' element={<Signup/>}/>
  <Route path='/explore' element={<Explore/>}/>
  <Route path='/create' element={<CreateNFT/>}/>
  <Route path='/nft/:id' element={<NftDetails/>}/>
  <Route path='/category/:id' element={<CategorySingle/>}/>
  <Route path='/auction' element={<Auction/>}/>
  <Route path='/wallet' element={<UserWallet/>}/>
  <Route path='/author' element={<UserAccount/>}/>
  <Route path='/collections' element={<NFTCollections/>}/>
  <Route path='/collections/:id' element={<CollectionSingle/>}/>
  <Route path='/author/:id' element={<AuthorProfile/>}/>
  <Route path="/signout" element={<Signout/>}/>
  <Route path="/help" element={<HelpCenter/>}/>
  <Route path='/activities' element={<SiteActivity/>}/>
  <Route path="/verify/:email/:token/:userId" element={<UserVerify/>}/>
  <Route path='/404' element={<Error404/>}/>
  <Route path='*' element={<Error404/>}/>
  </Routes>
  <FooterDiv/>
  </Router>
  </>
  );
}

export default App;
