import styled from "styled-components";

export const MainImage =styled.div`
background-color: var(--skeletons-color);
height: 250px;
border-radius: 10px;
animation: skeleton ease 1s infinite alternate;
@keyframes skeleton {
    to{
     opacity: 0.65;
    }
}



`
export const Pill =styled.span`
width: 60px;
height: 24px;
background-color: var(--card-background-color);
top: 10px;
right: 25px;
border-radius: 50rem;
position: absolute;
animation: skeleton ease 1s infinite alternate;
@keyframes skeleton {
    to{
     opacity: 0.65;
    }
}

`

export const Text = styled.div`
    background: var(--skeletons-color);
    width: ${props => props.width}px;
    border-radius: ${props => props.radius}px;;
    height: ${props => props.height}px;
    margin-bottom: ${props => props.mb}px;
    animation: skeleton ease 1s infinite alternate;
    @keyframes skeleton {
        to{
         opacity: 0.65;
        }
    }
    
`