import { MainImage, Text } from "./styles";

const CollectionSkeleton = () => {
    return ( <>
      <div className="col-md-6 col-lg-4">
                <div className="nft-item collection-item">
                    <div className="nft-inner">
                        <div className="coll-thumb-wrapper">
                        <MainImage></MainImage>
                           
                        </div>
                        <div className="nft-content">
                            <div className="collection-title">
                            <Text height="25" width="100" radius="10"></Text>
                            <Text height="15" width="50" radius="10"></Text>
                            </div>

                            <div  className="author-details d-flex flex-wrap justify-content-between align-items-center">
                                <div
                                    className="single-author d-flex align-items-center">
                                     <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </div>
                                <Text height="20" width="40" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   <div className="col-md-6 col-lg-4">
                <div className="nft-item collection-item">
                    <div className="nft-inner">
                        <div className="coll-thumb-wrapper">
                        <MainImage></MainImage>
                           
                        </div>
                        <div className="nft-content">
                            <div className="collection-title">
                            <Text height="25" width="100" radius="10"></Text>
                            <Text height="15" width="50" radius="10"></Text>
                            </div>

                            <div  className="author-details d-flex flex-wrap justify-content-between align-items-center">
                                <div
                                    className="single-author d-flex align-items-center">
                                     <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </div>
                                <Text height="20" width="40" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   <div className="col-md-6 col-lg-4">
                <div className="nft-item collection-item">
                    <div className="nft-inner">
                        <div className="coll-thumb-wrapper">
                        <MainImage></MainImage>
                           
                        </div>
                        <div className="nft-content">
                            <div className="collection-title">
                            <Text height="25" width="100" radius="10"></Text>
                            <Text height="15" width="50" radius="10"></Text>
                            </div>

                            <div  className="author-details d-flex flex-wrap justify-content-between align-items-center">
                                <div
                                    className="single-author d-flex align-items-center">
                                     <Text height="50" width="50" radius="500"></Text>
                                    <Text height="20" width="100" radius="10"></Text>
                                </div>
                                <Text height="20" width="40" radius="10"></Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
    </> );
}
 
export default CollectionSkeleton;