import HomeAuction from "./home/auction";
import HomeBanner from "./home/banner";
import HomeCategory from "./home/category";
import LastestNews from "./home/news";
import TopCollectors from "./home/topCollectors";
import TrendingCollections from "./home/trendingCollections";

const HomePage = () => {
    return (<>
        <HomeBanner/>
        <HomeCategory/>
        <HomeAuction/>
        <TopCollectors/>
        <TrendingCollections/>
        <LastestNews/>
    </>);
}

export default HomePage;