import axios from "axios";
import { useState } from "react";
import { LoadingButton } from "../components/loading";

const AddAuction = ({ data, id }) => {
    const [regResponse, setRegResponse] = useState('')
    const [isBidLoading, setIsBidLoading] = useState(false)
    const [auctionPrice, setAuctionPrice] = useState("")
    const [auctionEnd, setAuctionEnd] = useState("")
    const [auctionUnits, setAuctionUnits] = useState("")
    const closeModal = (() => {
        let modal = document.getElementById("myModal");
        modal.style.display = "none";
        modal.style.transition = "1s ease-out";
    })

    const addAuction = (e) => {
        e.preventDefault()
        setIsBidLoading(true)
        const payload = {
            nftId: id,
            ends: auctionEnd,
            units: auctionUnits,
            price: auctionPrice
        }
        console.log(payload)
        axios.post("https://api.artsfields.com/user/addAuction", payload)
        
        .then(res=>{
           // console.log(res)
            // setRegResponse(<></>)
            window.location.reload()
        })
        .catch(err=>{
            console.log(err.response)
        })
       
    }

    return (<>

                <div className="modal-header">
                    <div className="modal-title">
                        Place Bid
                    </div>
                    <span className="close" onClick={closeModal}>&times;</span>
                </div>
                <div className="modal-body">
                    <p>Current Sales Price:  <b>{data.price}{(`${data.currency}`).toUpperCase()}</b> </p>
                    <form className="account-form" onSubmit={addAuction}>
                        <div className="form-floating mb-3" id="enterBidding">
                            <input type="text" className="form-control" placeholder="Action Price" value={auctionPrice} onChange={(e) => setAuctionPrice(e.target.value)} />
                            <label htmlFor="enterBidding">Auction Price in ETH</label>
                        </div>
                        <div className="form-floating mb-3" id="enterBidding">
                            <input type="text" className="form-control" placeholder="Bid Amount" value={auctionUnits} onChange={(e) => setAuctionUnits(e.target.value)} />
                            <label htmlFor="enterBidding">Units</label>

                        </div>
                        <div className="form-floating mb-3" id="enterBidding">
                            <input type="datetime-local" className="form-control" placeholder="Bid Amount" value={auctionEnd} onChange={(e) => setAuctionEnd(e.target.value)} />
                            <label htmlFor="enterBidding">Aution Ends</label>

                        </div>
                        {regResponse}
                        <div className="form-group">
                            {isBidLoading ? <button className="d-block default-btn move-top disabled" disabled={true}><LoadingButton><div></div><div></div><div></div><div></div></LoadingButton></button> :
                                <>  <button className="d-block default-btn move-top" type="submit"><span>Place Now</span></button></>}
                        </div>

                    </form>
                </div>
          
    </>);
}

export default AddAuction;